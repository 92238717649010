import React, { useRef } from 'react';
import { BlockTitle, SwiperSlide, Swiper, Block, PhotoBrowser } from 'framework7-react';

const ImageGallery = (props) => {

    let _activeIndex = 0;
    const standalone = useRef(null);
    if (props.imageArray.length === 0)
        return null;

    const photos = props.imageArray.map((imageUrl, index) => {
        return (imageUrl && imageUrl !== "") ? imageUrl : 'https://picsum.photos/200'
    });

    const ret = props.imageArray.map((imageUrl, index) => {
        const gImageUrl = (imageUrl && imageUrl !== "") ? imageUrl : 'https://picsum.photos/200';

        return (
            <SwiperSlide key={'image-' + index} style={{ backgroundImage: "url(" + gImageUrl + ")" }} />
        )
    });

    return (
        <div>
            <PhotoBrowser
                photos={photos}
                navbarShowCount={false}
                ref={standalone} />
            <BlockTitle medium>
                {props.galleryTitle}
            </BlockTitle>
            <Block>
                <Swiper
                    onActiveIndexChange={(e) => { _activeIndex = e.activeIndex }}
                    onClick={() => { standalone.current.open(_activeIndex) }}
                    className="galleria"
                    pagination>
                    {ret}
                </Swiper>
            </Block>
        </div >)
}

export default ImageGallery;