import React from 'react';
import { List, ListItem, Icon } from 'framework7-react';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../../analytics-register';
import Commons from '../../commons';

const ContactList = (props) => {

    const contactItems = [];
    const content = props.content;

    if (content.webUrl) {
        contactItems.push(
            <ListItem key='webUrl'
                header='Sito web:'
                title={content.webUrl}
                onClick={() => {
                    AnalyticsRegister.logEvent(
                        ANALYTICS_EVENT.WEB_SITE_URL_CLICK,
                        {
                            ContentName: content.title,
                            ContentId: content.id,
                            ContentType: Commons.getStringContentView(content.ctnView),
                            Url: content.webUrl
                        });
                    window.open(encodeURI(content.webUrl), '_blank')
                }}>
                <Icon slot="media" ios="f7:globe" aurora="f7:globe" md="material:language" />
            </ListItem>);
    }

    if (content.phone) {
        contactItems.push(
            <ListItem key='phone'
                header='Telefono:'
                title={content.phone}
                onClick={() => {
                    AnalyticsRegister.logEvent(
                        ANALYTICS_EVENT.PHONE_NUMBER_CLICK,
                        {
                            ContentName: content.title,
                            ContentId: content.id,
                            ContentType: Commons.getStringContentView(content.ctnView),
                            PhoneNumber: content.phone
                        });
                    window.open(encodeURI("tel:" + content.phone), '_system')
                }}>
                <Icon slot="media" ios="f7:phone" aurora="f7:phone" md="material:local_phone" />
            </ListItem>);
    }

    if (content.email) {
        contactItems.push(
            <ListItem key='email'
                header='Email:'
                title={content.email}
                onClick={() => {
                    AnalyticsRegister.logEvent(
                        ANALYTICS_EVENT.EMAIL_CLICK,
                        {
                            ContentName: content.title,
                            ContentId: content.id,
                            ContentType: Commons.getStringContentView(content.ctnView),
                            Email: content.email
                        });
                    window.open(encodeURI("mailto:" + content.email), '_system')
                }}>
                <Icon slot="media" ios="f7:envelope" aurora="f7:envelope" md="material:mail" />
            </ListItem>);
    }

    if (content.fax) {
        contactItems.push(
            <ListItem key='fax'
                header='Telefono:'
                title={content.fax}
                onClick={() => { window.open(encodeURI("tel:" + content.fax), '_system') }}>
                <Icon slot="media" ios="f7:printer" aurora="f7:printer" md="material:print" />
            </ListItem>);
    }
    //MANCA ECOMMERCE
    if (content.youtubeUrl) {
        contactItems.push(
            <ListItem key='youtube'
                header='Youtube:'
                title={content.youtubeName ? content.youtubeName : ''}
                onClick={() => { window.open(encodeURI(content.youtubeUrl), '_blank') }}>
                <Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />
            </ListItem>);
    }

    if (content.attchmUrl) {
        contactItems.push(
            <ListItem key='attchm'
                header='PDF:'
                title={content.attchmName ? content.attchmName : ''} after={content.attchmDescr}
                onClick={() => {
                    AnalyticsRegister.logEvent(
                        ANALYTICS_EVENT.ATTACHMENT_CLICK,
                        {
                            ContentName: content.title,
                            ContentId: content.id,
                            ContentType: Commons.getStringContentView(content.ctnView),
                            DocumentName: content.attchmName
                        });

                    window.open(encodeURI(content.attchmUrl), '_blank')

                    // downloadPdf(content.attchmUrl)
                    //     .then((file) => {
                    //         let newtab = window.open('about:blank', '_blank');
                    //         newtab.document.write(
                    //             "<iframe width='100%' height='100%' src='" +
                    //             encodeURI(file) + "'></iframe>"
                    //         )
                    //    

                }
                } >
                <Icon slot="media" ios="f7:arrow_down_doc" aurora="f7:arrow_down_doc" md="material:attach_file" />
            </ListItem >);
    }

    if (content.skype) {
        contactItems.push(
            <ListItem key='skype'
                header='Skype:'
                title={content.skype}
                onClick={() => { window.open(encodeURI("skype:" + content.skype + "?add"), '_blank') }}>
                {/*<Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />*/}
            </ListItem>);
    }

    if (content.fbUrl) {
        contactItems.push(
            <ListItem key='fb'
                header='Facebook:'
                title={content.fbUrl}
                onClick={() => { window.open(encodeURI(content.fbUrl), '_blank') }}>
                <Icon slot="media" ios="f7:logo_facebook" aurora="f7:logo_facebook" md="material:facebook" />
            </ListItem>);
    }

    if (content.instUrl) {
        contactItems.push(
            <ListItem key='inst'
                header='Instagram:'
                title={content.instUrl}
                onClick={() => { window.open(encodeURI(content.instUrl), '_blank') }}>
                <Icon slot="media" ios="f7:logo_instagram" aurora="f7:logo_instagram" md="f7:logo_instagram" />
            </ListItem>);
    }

    if (content.linkedinUrl) {
        contactItems.push(
            <ListItem key='linkedin'
                header='Linkedin:'
                title={content.linkedinUrl}
                onClick={() => { window.open(encodeURI(content.linkedinUrl), '_blank') }}>
                <Icon slot="media" ios="f7:logo_linkedin" aurora="f7:logo_linkedin" md="f7:logo_linkedin" />
            </ListItem>);
    }

    if (content.twUrl) {
        contactItems.push(
            <ListItem key='tw'
                header='Twitter:'
                title={content.twUrl}
                onClick={() => { window.open(encodeURI(content.twUrl), '_blank') }}>
                <Icon slot="media" ios="f7:logo_twitter" aurora="f7:logo_twitter" md="f7:logo_twitter" />
            </ListItem>);
    }

    /*if (content.googleUrl) {
        contactItems.push(
            <ListItem key='google'
                header='Google:'
                title={content.googleUrl}
                onClick={() => { window.open(encodeURI(content.googleUrl), '_blank') }}>
                <Icon slot="media" ios="f7:logo_google" aurora="f7:logo_google" md="f7:logo_google" />
            </ListItem>);
    }*/

    if (content.pinUrl) {
        contactItems.push(
            <ListItem key='pin'
                header='Pinterest:'
                title={content.pinUrl}
                onClick={() => { window.open(encodeURI(content.pinUrl), '_blank') }}>
                {/*<Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />*/}
            </ListItem>);
    }


    if (contactItems.length === 0)
        return null;

    return (
        <List style={{ marginTop: '30px' }} /*className="lista-contatti"*/>
            {contactItems}
        </List>)
}



// const downloadPdf = (url) => {

//     return new Promise(function (resolve, reject) {
//         fetch(url).then(data => {
//             const blob = data.blob();
//             console.log(blob)
//             return blob;
//         }).then(blob => {
//             let reader = new window.FileReader();

//             reader.onloadend = function () {
//                 const data = reader.result;
//                 resolve(data)
//             };

//             reader.readAsDataURL(blob);
//         })
//     });
// }
export default ContactList;