import * as moment from "moment";

import { PkStore } from "../../redux/store";
import { PkReduxActions } from "../../redux/actions";
import { PkStorage } from "../../storage";
import { Pk } from "../../index";
import { PkCommons } from "../../commons";
import { PkApiNet } from "../../api/net";
import { PkCrypto } from "../../crypto/crypto";
import { PkApiContacts } from "../../api/contacts";

export class PkApiRooms {
  static _onIncomingRoomMessage = (message) => {
    if (
      PkApiRooms.onIncomingRoomMessage &&
      PkApiRooms.onIncomingRoomMessage instanceof Function
    ) {
      PkApiRooms.onIncomingRoomMessage(message);
    }
  };

  static onIncomingRoomMessage = null;

  static deleteContactRoom = (roomId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url: Pk.getConfig().apiUrl + "/services/rooms/" + roomId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let rooms =
              PkStore.getState().contactRooms !== null
                ? [...PkStore.getState().contactRooms]
                : [];
            let messageRoomIndex = PkCommons.getElementIndexFromUniqueId(
              rooms,
              roomId
            );
            if (messageRoomIndex >= 0) {
              rooms.splice(messageRoomIndex, 1);
              PkStore.get().dispatch(PkReduxActions.setContactRooms(rooms));
            }
            PkStorage.deleteRoom(roomId);
          }

          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static GetDecryptedRoom = (room, storedRooms) => {
    let localRoom = null;
    let localRoomIndex = storedRooms
      ? PkCommons.getElementIndexFromUniqueId(storedRooms, room.UniqueId)
      : -1;
    if (localRoomIndex >= 0) {
      localRoom = storedRooms[localRoomIndex];
    }

    if (localRoom && localRoom.LastMessageDate === room.LastMessageDate) {
      room.LastMessageText = localRoom.LastMessageText;
    } else {
      room.LastMessageText = PkCrypto.decrypt(
        room.LastMessageText,
        room.LastMessageCryptoSchema
      );
    }

    if (!room.LastMessageText) {
      room.LastMessageText = "";
    }

    if (localRoom) {
      room.Subject = localRoom.Subject;
    } else {
      room.Subject = PkCrypto.decrypt(room.Subject, room.CryptoSchema);
    }
    if (!room.Subject) {
      room.Subject = "";
    }

    return room;
  };

  static _gettingContactRooms = false;

  static getContactRoom = (roomId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/rooms/" + roomId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let storedRooms = PkStorage.getRooms();
            response.data = PkApiRooms.GetDecryptedRoom(
              response.data,
              storedRooms
            );
          }

          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getContactRooms = () => {
    return new Promise((resolve, reject) => {
      if (PkApiRooms._gettingContactRooms === true) {
        resolve({ success: false, message: "loading already in progress" });
        return;
      }
      PkApiRooms._gettingContactRooms = true;

      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/rooms",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let storedRooms = PkStorage.getRooms();

            PkCommons.changeArrayItemsAsync(
              response.data,
              (item) => {
                return PkApiRooms.GetDecryptedRoom(item, storedRooms);
              },
              2,
              (perc) => {
                PkStore.get().dispatch(
                  PkReduxActions.setContactRoomsLoadingPerc(perc)
                );
              }
            )
              .then((decryptedRooms) => {
                let rooms =
                  PkStore.getState().contactRooms !== null
                    ? [...PkStore.getState().contactRooms]
                    : null;
                if (
                  rooms === null ||
                  JSON.stringify(rooms) !== JSON.stringify(decryptedRooms)
                ) {
                  PkStorage.setRooms(decryptedRooms);
                  PkStore.get().dispatch(
                    PkReduxActions.setContactRooms(decryptedRooms)
                  );
                }
                resolve(response);
              })
              .catch(function (errorMessage) {
                reject(new Error(errorMessage));
              })
              .then(() => {
                PkApiRooms._gettingContactRooms = false;
              });
          } else {
            resolve(response);
            PkApiRooms._gettingContactRooms = false;
          }
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
          PkApiRooms._gettingContactRooms = false;
        });
    });
  };

  static markMessagesAsRead = (roomId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("read", true);

      PkApiNet.callApi({
        method: "put",
        url: Pk.getConfig().apiUrl + "/services/rooms/" + roomId + "/messages",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            let rooms =
              PkStore.getState().contactRooms !== null
                ? [...PkStore.getState().contactRooms]
                : [];
            let messageRoomIndex = PkCommons.getElementIndexFromUniqueId(
              rooms,
              roomId
            );
            if (messageRoomIndex >= 0) {
              rooms[messageRoomIndex].UnreadMessages = 0;
              PkStore.get().dispatch(PkReduxActions.setContactRooms(rooms));
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static setMute = (roomId, mute) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("mute", mute);

      PkApiNet.callApi({
        method: "put",
        url: Pk.getConfig().apiUrl + "/services/rooms/" + roomId,
        data: bodyFormData,
      })
        .then(function (response) {
          let rooms =
            PkStore.getState().contactRooms !== null
              ? [...PkStore.getState().contactRooms]
              : [];
          let messageRoomIndex = PkCommons.getElementIndexFromUniqueId(
            rooms,
            roomId
          );
          if (messageRoomIndex >= 0) {
            rooms[messageRoomIndex].Mute = !(
              rooms[messageRoomIndex].Mute === true
            );
            PkStore.get().dispatch(PkReduxActions.setContactRooms(rooms));
          }

          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static startNewRoom = (subject, targetContacts, isGroup) => {
    return new Promise((resolve, reject) => {
      let targetContactsIds = [];
      targetContacts.forEach((contact) => {
        targetContactsIds.push(contact.UniqueId);
      });

      if (!targetContactsIds.includes(PkStorage.getProfile().UniqueId)) {
        targetContactsIds.push(PkStorage.getProfile().UniqueId);
      }

      const bodyFormData = new FormData();
      bodyFormData.set(
        "subject",
        PkCrypto.encrypt(
          subject,
          PkApiContacts.getContactsPublicKeys(targetContactsIds)
        )
      );
      bodyFormData.set("targetContacts", targetContactsIds.toString());
      bodyFormData.set("isGroup", isGroup);
      bodyFormData.set("cryptoschema", Pk.getConfig().cryptoSchema);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/rooms",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            let rooms = PkApiRooms.getNewRoomsList(
              PkApiRooms.GetDecryptedRoom(response.data)
            );
            PkStore.get().dispatch(PkReduxActions.setContactRooms(rooms));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getNewRoomsList = (newRoom) => {
    let rooms =
      PkStore.getState().contactRooms !== null
        ? [...PkStore.getState().contactRooms]
        : [];
    if (!rooms) {
      rooms = [];
      rooms.push(newRoom);
    } else {
      let exists = false;
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i].UniqueId === newRoom.UniqueId) {
          exists = true;
          break;
        }
      }
      if (exists === false) {
        let inserted = false;
        //se la nuova stanza ha già dei messaggi la devi inserire nell'ordine coretto
        if (newRoom.LastMessageDate) {
          for (let i = 0; i < rooms.length; i++) {
            if (
              !rooms[i].LastMessageDate ||
              moment
                .utc(rooms[i].LastMessageDate)
                .isBefore(newRoom.LastMessageDate)
            ) {
              PkCommons.insertAt(rooms, i, newRoom);
              inserted = true;
              break;
            }
          }
        }
        //se la nuova stanza non ha messaggi, o non ho trovato una posizone la inserisco in fondo
        if (inserted === false) {
          rooms.push(newRoom);
        }
      }
    }
    return rooms;
  };
}

export default PkApiRooms;
