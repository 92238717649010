import React from 'react';
import { connect } from 'react-redux';
import { f7, Block, Icon, Card, Link } from 'framework7-react';
import ImageGallery from '../content-elements/image-gallery';

class PlateAssetsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTutorial: true,
        };
    }

    render() {

        const content = this.props.content;
        
        return (
            <Block style={{ marginTop: 0 }}>
                <div id='plate_footer' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                    {content.webUrl ?
                        <Card style={{ position: 'relative', width: '33%', textAlign: '-webkit-center' }}>
                            <Link onClick={() => { this.openUrl(content.webUrl) }}>
                                <Icon
                                    ios="f7:globe"
                                    aurora="f7:globe"
                                    md="material:language"
                                    style={{ margin: '10px' }} />
                            </Link>
                        </Card> : ""}

                    <Card style={{ position: 'relative', width: '33%', textAlign: '-webkit-center' }}>
                        <Link onClick={() => { this.goToContactPage(this.props.brand.name, this.props.content.title) }}>
                            <Icon
                                ios="f7:info_circle" aurora="f7:info_circle" md="f7:info_circle"
                                style={{ margin: '10px' }} />
                        </Link>
                    </Card>

                    <Card style={{ position: 'relative', width: '33%', textAlign: '-webkit-center' }}>
                        <Link onClick={() => { //todo: mettere il link corretto
                            this.props.shareContentHandler(content.webUrl)
                        }}>

                            <Icon
                                ios="material:share" aurora="material:share" md="material:share"
                                style={{ margin: '10px' }}
                                large
                                fill />
                        </Link>
                    </Card>
                </div>

                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />
            </Block>
        )
    }

    goToContactPage = (brandName, contentTitle) => {
        if (document.getElementsByClassName("card-opened").length > 0) {
            f7.card.close();
        }

        if (this.props.onInfoClickHandler) {
            this.props.onInfoClickHandler({ brandName: brandName, contentTitle: contentTitle });
        }
    }

    openUrl = (url) => {
        window.open(encodeURI(url), '_blank')
    }

    componentDidMount = () => {
        const header = document.getElementById('plate_footer');
        if (header) {
            this.props.contentHeightDefinedHandler(header.clientHeight)
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion


export default connect(mapStateToProps, mapDispatchToProps)(PlateAssetsView);