//import { isIOS } from "react-device-detect";

import { PkCordova } from "./cordova/index";
import { PkApiCalls } from "./api/calls/calls";
import { PkLog } from "./log";
import { PkStore } from "./redux/store";
import { PkReduxActions } from "./redux/actions";
import { PkApiNet } from "./api/net";
import { PkApiAuth } from "./api/auth";
import { PkApiRooms } from "./api/rooms/rooms";
import { PkApiRoomsMessages } from "./api/rooms/messages";
import { PkStorage } from "./storage";
import { Pk } from "./index";
import PkVoipNotifications from "./voip-notifications";
import { isIOS } from "react-device-detect";

export class PkNotifications {
  _messaging = null;

  static init_cordova_notifications = () => {
    PkLog.log("init_cordova_notifications");
    if (window.hasOwnProperty("cordova")) {

      PkVoipNotifications.initVoipNotifications();

      const firebase = window.cordova.plugins.firebase;
      if (firebase && firebase.messaging) {
        window.cordova.plugins.firebase.messaging.onTokenRefresh(function () {
          PkLog.log("Device token updated");
          window.cordova.plugins.firebase.messaging
            .getToken()
            .then(function (token) {
              PkLog.log("Got device token: ", token);
              PkStorage.setFirebaseToken(token);
              // PkNotifications.sendTokenToServer(token);
            });
        });
        // [END refresh_token]
        window.cordova.plugins.firebase.messaging.onMessage(function (payload) {
          PkLog.log("New foreground FCM message: ", payload);
          PkNotifications.handleMessageEvent(payload);
        });

        window.cordova.plugins.firebase.messaging.onBackgroundMessage(function (
          payload
        ) {
          PkLog.log("New background FCM message: ", payload);
          PkNotifications.handleMessageEvent(payload);
        });
      }
    }
  };

  static initWebNotifications = (firebase, firebaseVapidKey) => {
    PkLog.log("init_web_notifications");
    try {
      this._messaging = firebase.messaging();
      PkLog.log("_messaging.usePublicVapidKey(firebaseVapidKey)");
      this._messaging.usePublicVapidKey(firebaseVapidKey); //
    } catch (e) {
      PkLog.log("firebase.messaging() error", e);
    }

    navigator.serviceWorker.addEventListener("message", (messageEvent) => {
      if (PkApiAuth.isAuthenticated()) {
        PkNotifications.handleMessageEvent(messageEvent);
      }
    });

    // [START refresh_token]
    // Callback fired if Instance ID token is updated.
    if (this._messaging) {
      this._messaging.onTokenRefresh(() => {
        PkLog.log("onTokenRefresh");
        this._messaging
          .getToken()
          .then((refreshedToken) => {
            PkLog.log("Token refreshed.");
            // Send Instance ID token to app server.
            PkNotifications.sendTokenToServer(refreshedToken).then(function (
              result
            ) {
              PkLog.log(result);
            });
            // [END_EXCLUDE]
          })
          .catch((err) => {
            PkLog.log("Unable to retrieve refreshed token ", err);
          });
      });
    }
    // [END refresh_token]
  };

  static getFirebaseMessaging = () => {
    PkLog.log("getFirebaseMessaging");
    return this._messaging;
  };

  static checkServiceRegistration = () => {
    return new Promise((resolve, reject) => {
      if (!PkCordova.isCordova()) {
        if (this._messaging) {
          PkNotifications.checkServiceWebRegistration()
            .then(function (successResult) {
              resolve(successResult);
            })
            .catch(function (errorMessage) {
              reject(errorMessage);
            });
        } else {
          resolve(false);
        }
      } else {

        PkVoipNotifications.checkServiceRegistration();

        const firebase = window.cordova.plugins.firebase;
        if (firebase && firebase.messaging) {
          // [START refresh_token]
          // Callback fired if Instance ID token is updated.
          window.cordova.plugins.firebase.messaging
            .requestPermission({ forceShow: true })
            .then(function () {
              PkLog.log(
                "You'll get foreground notifications when a push message arrives"
              );

              window.cordova.plugins.firebase.messaging
                .getToken()
                .then(function (token) {
                  PkLog.log("Got device token: ", token);
                  PkStorage.setFirebaseToken(token);
                  // PkNotifications.sendTokenToServer(token);

                  if (PkStorage.getFirebaseToken() != null) {
                    PkLog.log("sending FIREBASE TOKEN to server");
                    PkNotifications.sendTokenToServer(
                      PkStorage.getFirebaseToken()
                    )
                      .then(function () {
                        PkLog.log(
                          "FIREBASE TOKEN to server: " +
                          PkStorage.getFirebaseToken()
                        );
                        resolve(true);
                      })
                      .catch(function () {
                        PkLog.log("FIREBASE TOKEN to server: error");
                        reject("FIREBASE TOKEN to server: error");
                      });
                  } else {
                    PkLog.log("no FIREBASE TOKEN to send");
                    reject("no FIREBASE TOKEN to send");
                  }
                });
            });
        } else {
          PkLog.log("no FIREBASE plugin found on device");
          reject("no FIREBASE plugin found on device");
        }
      }
    });
  };

  static checkServiceWebRegistration = () => {
    PkLog.log("checkServiceWebRegistration");
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          PkLog.log("Notification permission granted.");

          PkNotifications.turnOnNotification(resolve, reject);
        } else {
          PkLog.log("Unable to get permission to notify.");

          resolve(false);
        }
      });
    });
  };

  static turnOnNotification = async (resolve, reject) => {
    if (PkNotifications.getFirebaseMessaging()) {
      try {
        const currentToken =
          await PkNotifications.getFirebaseMessaging().getToken();
        if (currentToken) {
          PkNotifications.sendTokenToServer(currentToken)
            .then(function (successResult) {
              resolve(successResult);
            })
            .catch(function (errorMessage) {
              reject(new Error(errorMessage));
            });
        } else {
          // Show permission request.
          PkLog.log(
            "No Instance ID token available. Request permission to generate one."
          );
          resolve(false);
        }
      } catch (err) {
        if (err.code === "messaging/token-unsubscribe-failed")
          PkNotifications.turnOnNotification(resolve, reject);
        else {
          PkLog.log("An error occurred while retrieving token. ", err);
          reject(new Error(err));
        }
      }
    } else {
      resolve(false);
    }
  };

  // Send the Instance ID token your application server, so that it can:
  // - send messages back to this app
  // - subscribe/unsubscribe the token from topics
  static sendTokenToServer = (currentToken, tokenType) => {
    PkLog.log("sendTokenToServer");
    return new Promise((resolve, reject) => {
      PkLog.log("Sending token to server...");
      // TODO(developer): Send the current token to your server.

      const bodyFormData = new FormData();
      bodyFormData.set("token", currentToken);
      bodyFormData.set("deviceId", "1234567890");
      if (tokenType) {
        bodyFormData.set("type", tokenType);
      }

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/notifications",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          PkLog.log(
            "Sending token to server response: " + response.result.success
          );
          resolve(response.result.success);
        })
        .catch(function (errorMessage) {
          PkLog.log("Error sending token to server..." + errorMessage);
          reject(new Error(errorMessage));
        });
    });
  };

  static deleteTokenFromServer = (tokenType) => {
    PkLog.log("deleteTokenFromServer");
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("token", "");
      bodyFormData.set("deviceId", "1234567890");
      if (tokenType) {
        bodyFormData.set("type", tokenType);
      }

      PkApiNet.callApi({
        method: "delete",
        url: Pk.getConfig().apiUrl + "/services/notifications",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          PkLog.log(
            "Delete token from server response: " + response.result.success
          );
          resolve(response.result.success);
        })
        .catch(function (errorMessage) {
          PkLog.log("Error deleting token to server..." + errorMessage);
          reject(new Error(errorMessage));
        });
    });
  };

  static handleMessageEvent = (messageEvent) => {
    PkLog.log("messageEvent: ", messageEvent);

    if (PkApiAuth.isAuthenticated()) {
      let messageType = null;
      let messageContent = null;

      if (PkCordova.isCordova()) {
        messageType = messageEvent.messageType;
        messageContent = null;
        if (messageEvent.messageContent != null) {
          if (typeof messageEvent.messageContent === 'object') {
            messageContent = messageEvent.messageContent;
          }
          else {
            messageContent = JSON.parse("[" + messageEvent.messageContent + "]")[0];
          }
        }
      } else {
        if (messageEvent != null && messageEvent.data != null) {
          let message = messageEvent.data.data;
          if (
            !message &&
            messageEvent.data["firebase-messaging-msg-data"] != null &&
            messageEvent.data["firebase-messaging-msg-data"].data != null
          ) {
            message = messageEvent.data["firebase-messaging-msg-data"].data;
          }

          messageType = message.messageType;
          messageContent = null;

          if (message.messageContent != null)
            messageContent = JSON.parse(message.messageContent);
        }
      }

      PkLog.log("messageType: " + messageType);
      if (Pk.getConfig().callsEnabled) {
        if (messageType === "IncomingCall")
          PkNotifications.incomingCallHandler(messageContent);
        else if (messageType === "EndedCall")
          PkNotifications.endedCallHandler(messageContent);
        else if (messageType === "TargetRefused")
          PkNotifications.callRefusedByTarget(messageContent);
        else if (messageType === "CallHandledByGroup")
          PkNotifications.callHandledByGroup(messageContent);
      }
      if (
        messageType === "IncomingRoomMessage" ||
        messageType === "IncomingRoomSilentMessage"
      )
        PkNotifications.incomingRoomMessageHandler(messageContent);
    }
  };

  static incomingRoomMessageHandler = (message) => {
    PkLog.log("incomingRoomMessageHandler", message);

    message = PkApiRoomsMessages.GetDecryptedMessage(message);

    PkApiRooms._onIncomingRoomMessage(message);
    PkApiRoomsMessages.incomingMessageNotification(message);
  };

  static endedCallHandler = (endedCall) => {
    PkLog.log("endedCallHandler");

    PkLog.log("endedCall: " + endedCall.UniqueId);

    if (PkCordova.isCordova() && isIOS) {
      PkVoipNotifications.endVoipCall();
    }
    if (
      PkStore.getState().incomingCall != null &&
      endedCall.UniqueId === PkStore.getState().incomingCall.UniqueId
    ) {
      PkStore.get().dispatch(PkReduxActions.setIncomingCall(null));
      PkApiCalls.stopIncomingCallAudioAndVibration();
    } else if (
      PkApiCalls.getActiveCall() != null &&
      PkApiCalls.getActiveCall().UniqueId === endedCall.UniqueId
    ) {
      PkApiCalls.closeActiveCall();
    }
  };

  static callRefusedByTarget = (data) => {
    PkLog.log(
      "targetRefused: call " +
      data.Call.UniqueId +
      " target: " +
      data.Target.Contact.UniqueId
    );
    PkLog.log("activCall", PkApiCalls.getActiveCall());
    if (
      PkApiCalls.getActiveCall() != null &&
      data.Call.UniqueId === PkApiCalls.getActiveCall().UniqueId
    ) {
      PkApiCalls._onCallDeclinedByTarget();

      PkLog.log(
        `participants n°:` + PkStore.getState().activeCallParticipants.length
      );
      if (PkStore.getState().activeCallParticipants.length === 0)
        PkApiCalls.closeActiveCall();
    }

    PkApiCalls.playAudioFromPhoneState(PkApiCalls.PhoneState.PHONE_BUSY);
  };

  static callHandledByGroup = (data) => {
    PkLog.log(
      "callHandledByGroup: call " +
      data.Call.UniqueId +
      " target: " +
      data.Contact.UniqueId
    );

    PkApiCalls.stopIncomingCallAudioAndVibration();

    if (
      PkStore.getState().incomingCall != null &&
      data.Call.UniqueId === PkStore.getState().incomingCall.UniqueId
    )
      PkStore.get().dispatch(PkReduxActions.setIncomingCall(null));
  };

  static incomingCallHandler = (data) => {
    PkLog.log("incomingCallHandler");
    PkLog.log("incomingCall: " + data.Call.UniqueId);

    PkApiCalls.getCurrentCalls()
      .then(function (response) {
        if (response.result.success === true) {
          for (var i = 0; i < response.data.length; i++) {
            let call = response.data[i];
            if (call !== null && call.UniqueId === data.Call.UniqueId) {
              if (!call.Caller) call.Caller = data.Call.Caller; //il Caller dalla call che proviene dalla notifica, è l'utente che ha contattato il destinatario, mentre il caller della call vera e propria è chi ha avviato la chiamata
              if (!call.Caller) PkApiCalls.getCallCaller(call);
              PkApiCalls.setIncomingCall(call);
            }
          }
        } else {
          PkLog.log("getCurrentCalls result error: " + response.result.message);
        }
      })
      .catch(function (response) {
        PkLog.log("getCurrentCalls error: " + response.message);
      })
      .then(() => {
        //nel caso in cui un utente avvia e interrompe una chiamata ricevo la notifica di tipo incomingCall dopo la notifica EndedCall
        //percui, se non ci sono chiamate in arrivo fermo l'audio
        //devo mettere un ritardo peché la parte nativa impiega tempo ad avviare l'audio, quindi se lo interrompo in modo sincrono il comando arriva prima dell'avvio dell'audio
        setTimeout(() => {
          if (!PkApiCalls.getIncomingCall()) {
            PkLog.log("PkApiCalls.stopIncomingCallAudioAndVibration()");
            PkApiCalls.stopIncomingCallAudioAndVibration();
          }
        }, 2000);
      });
  };
}
export default PkNotifications;
