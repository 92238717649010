import moment from "moment-timezone";
import {
  isAndroid,
  isIOS,
  isBrowser,
  isTablet,
  isChrome,
  isMacOs,
  isWindows,
} from "react-device-detect";

import { PkApiNet } from "./api/net";
import { PkStorage } from "./storage";
import { Pk } from "./index";
import { PkCryptoAES } from "./crypto/aes";

export const LOG_TYPE = {
  NONE: 0,
  ERROR: 10,
  VERBOSE: 30,
};

export class PkLog {
  static _internalLog = [];

  static _archiveLog = (method, arg1, arg2) => {
    const logDate = moment
      .utc(new Date())
      .tz("Europe/Rome")
      .format("YYYY-MM-DD HH:mm:ss.SSS");
    let logText = PkLog._objToString(arg1);
    if (arg2) {
      logText += " " + PkLog._objToString(arg2);
    }
    PkLog._internalLog.push(logDate + ": [" + method + "] - " + logText);
  };

  static _objToString = (obj) => {
    if (typeof obj === "object") {
      return JSON.stringify(obj);
    } else {
      return obj;
    }
  };

  static _writeLogInConsole = (method, arg1, arg2) => {
    console[method](arg1);
    if (arg2) {
      console[method](arg2);
    }
  };

  static _log = (arg1, arg2) => {
    PkLog._writeLogInConsole("log", arg1, arg2);
  };
  static log = (arg1, arg2) => {
    PkLog._log(arg1, arg2);
    PkLog._archiveLog("VERBOSE", arg1, arg2);
  };

  static _error = (arg1, arg2) => {
    PkLog._writeLogInConsole("error", arg1, arg2);
  };
  static error = (arg1, arg2) => {
    PkLog._error(arg1, arg2);
    PkLog._archiveLog("ERROR", arg1, arg2);
  };

  static init = () => {
    if (Pk.getConfig().logType < LOG_TYPE.VERBOSE) {
      PkLog._log = () => { };
    }
    if (Pk.getConfig().logType < LOG_TYPE.ERROR) {
      PkLog._error = () => { };
    }
  };

  static sendLog = (errorMsg, errorInfo) => {
    return new Promise((resolve, reject) => {
      if (!Pk.getConfig()) {
        console.log("remote-assistance not yet initialized");
        resolve();
      } else {
        const bodyFormData = new FormData();
        const now = new Date();
        const requestTime = Date.UTC(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours(),
          now.getMinutes(),
          now.getSeconds()
        );
        const data = {
          appCode: Pk.getConfig().appCode,
          time: requestTime,
          errorMsg: PkLog._objToString(errorMsg),
          errorInfo: PkLog._objToString(errorInfo),
          appLog: PkLog._internalLog.join("\n"),
          username: PkStorage.getProfile()
            ? PkStorage.getProfile().UniqueId
            : null,
          isBrowser: isBrowser,
          isChrome: isChrome,
          isIOS: isIOS,
          isAndroid: isAndroid,
          isMacOs: isMacOs,
          isWindows: isWindows,
          isTablet: isTablet,
        };

        bodyFormData.set("appCode", Pk.getConfig().appCode);
        bodyFormData.set("now", requestTime);
        bodyFormData.set(
          "data",
          PkCryptoAES.encryptAES(
            JSON.stringify(data),
            Pk.getConfig().appCode + "-" + requestTime
          )
        );

        PkApiNet.callApi({
          method: "post",
          data: bodyFormData,
          url: Pk.getConfig().apiUrl + "/services/log",
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (errorMessage) {
            reject(new Error(errorMessage));
          });
      }
    });
  };
}
export default PkLog;
