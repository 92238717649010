import React, { useRef } from 'react'
import { Popup, Navbar, NavTitle, NavLeft, NavRight, BlockTitle, Page, Block, Button, Fab, Icon } from 'framework7-react';
import ReactHtmlParser from 'react-html-parser'

const TermsPagePopup = (props) => {

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    return messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Popup
      navBarTitle={props.navBarTitle}
      opened={props.opened}
      tabletFullscreen={true}>
      <Page>
        <Navbar mdCenterTitle={true}>
          <NavLeft>
            <img slot="media" src='./img/spacing_icon.png'
              className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
          </NavLeft>
          <NavTitle>{props.navBarTitle}</NavTitle>
          <NavRight>
            <img slot="media" src={props.logo}
              className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' /></NavRight>
        </Navbar>
        <BlockTitle large>{props.title}</BlockTitle>

        <Fab position="right-bottom" slot="fixed" onClick={() => { scrollToBottom(); }}>
          <Icon ios="f7:arrow_down" aurora="f7:arrow_down" md="f7:arrow_down" />
        </Fab>

        <Block>
          <p>
            {ReactHtmlParser(props.description)}
          </p>
          <Block>
            <Button onClick={props.confirmButtonClicked} style={{ marginLeft: '40px', marginRight: '40px' }} large fill>{props.confirmButtonText}</Button>
            <Button onClick={props.dismissButtonClicked} style={{ background: 'transparent', margin: 'auto', width: '50%', color: "var(--f7-text-color)" }} large fill>{props.dismissiButtonText}</Button>
          </Block>
        </Block>
        <div ref={messagesEndRef} />
      </Page>
    </Popup>
  )
}

export default TermsPagePopup;