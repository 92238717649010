import React from 'react';
import { connect } from 'react-redux';
import { Page, Navbar, Link, NavRight, Block, Sheet, PageContent, } from 'framework7-react';
import localeStrings from './content-kml-local';
import Commons from '../commons'
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../analytics-register'
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import { PkLog } from '../pikkart-cms/log';
var CATEGORY_IDS = {
    PARKS: 13733,
    PARKS_ITA: 13634,
    CYCLE_PATHS: 13732,
    CYCLE_PATHS_ITA: 13635,
    STALKING_DOGS: 13731,
    STALKING_DOGS_ITA: 13636,
};

class ContentKML extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // infoPopupOpened: false,
            sheetOpened: false,
        };
    }

    map = null;
    menuItem = null;
    kmlJson = null;
    selectedId = null;

    render() {
        this.menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page>

                <Navbar>
                    <PikkartNavbar
                        title={this.menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={this.menuItem.catIndex > 1}
                    />
                </Navbar>
                <div id={this.getMapId(this.menuItem)}
                    // style={{ height: '500px' }}
                    style={{ height: '100%' }}
                ></div>
                {this.getDetailPopup()}
            </Page>
        )
    }

    getDetailPopup = () => {
        if (this.selectedId == null)
            return ("");

        let item = null;
        let title = localeStrings.noDataTitle;
        let content = () => { return (<Block> {localeStrings.noDataText} </Block>) };

        if (this.state.sheetOpened) {
            this.kmlJson['features'].forEach(element => {
                if (element.id === this.selectedId)
                    item = element;
            });

            switch (this.menuItem['id']) {
                case CATEGORY_IDS.PARKS:
                case CATEGORY_IDS.PARKS_ITA:
                    title = item['properties']['NOMEPARCO'];
                    // content = () => { return this.getPopupContent(item, ['UBICAZIONE', 'URLSCHEDA']) };
                    content = () => {

                        let link = ((item['properties']['URLSCHEDA'] !== undefined) ?
                            (<p>
                                <Link onClick={() => { window.open(item['properties']['URLSCHEDA'], '_blank') }}>{localeStrings.urlLabel}</Link>
                            </p>)
                            : "");

                        let features = () => {
                            let feats = ['ILLUMINAZIONE', 'GIOCO_BIMBI', 'SPAZI_SPORT', 'RISTORO', 'ARREDI', 'SERVIZI_IGIENICI', 'ALBERI_PREGIO', 'ALBERI_TUTELATI'];
                            let prints = [];
                            // prints.push(localeStrings.parkFeaturesLabel, <br/>);

                            feats.forEach((element) => {
                                if (item['properties'][element] === 1)
                                    prints.push(<li key={feats.indexOf(element)}>{localeStrings[element]}</li>);
                            });

                            // if (prints.length > 2)
                            if (prints.length > 0)
                                return (
                                    <ul>
                                        {prints}
                                    </ul>
                                );
                            else
                                return "";
                        };

                        return (
                            <Block>
                                <p>
                                    {item['properties']['UBICAZIONE']}
                                </p>
                                {features()}
                                {link}
                            </Block>
                        );
                    };
                    break;
                case CATEGORY_IDS.CYCLE_PATHS:
                case CATEGORY_IDS.CYCLE_PATHS_ITA:
                    title = item['properties']['RETE_STRA'] + " - " + item['properties']['AMBITO'];
                    content = () => {
                        return (
                            <Block>
                                <p>
                                    {item['properties']['TIPO'] + " " + item['properties']['CLA_MOB']}
                                </p>
                                <p>
                                    {item['properties']['CONTESTO']}
                                </p>
                                <p>
                                    {item['properties']['CLA_CDS']}
                                </p>
                                <p>
                                    {item['properties']['SEDE']}
                                </p>
                                <p>
                                    {item['properties']['MONO_BID']}
                                </p>
                            </Block>
                        );
                    };
                    break;
                case CATEGORY_IDS.STALKING_DOGS:
                case CATEGORY_IDS.STALKING_DOGS_ITA:
                    title = ((item['properties']['PARCO'] !== undefined && item['properties']['PARCO'] !== "") ? item['properties']['PARCO'] : localeStrings.stalkingDogsPopupTitle);
                    content = () => {
                        return (
                            <Block>
                                <p>
                                    {item['properties']['AREA_SGAMBAMENTO_CANI']}
                                </p>
                            </Block>
                        );
                    };
                    break;

                default:
                    break;
            }
        }

        return (
            <Sheet className="info-popup" opened={this.state.sheetOpened} onSheetClosed={() => { this.setState({ sheetOpened: false }) }}>
                <Navbar title={title}>
                    <NavRight>
                        <Link sheetClose>{localeStrings.close}</Link>
                    </NavRight>
                </Navbar>
                {/*  Scrollable sheet content */}
                <PageContent style={{ padding: '0px' }}>
                    {content()}
                </PageContent>
            </Sheet>
        );
    }

    getBackButton = (item) => {
        if (item.catIndex > 1) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return (
                <img slot="media" src='./img/logo/logo.png'
                    className="icon material-icons" style={{ fontSize: 'xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }

    parseKML = (kmlJson) => {
        this.kmlJson = kmlJson;
        var kmlLayer = new window.google.maps.KmlLayer(this.menuItem['attchmUrl'], {
            suppressInfoWindows: true,
            preserveViewport: true,
            map: this.map
        });

        kmlLayer.addListener('click', (event) => {
            PkLog.log('kml layer click');
            this.selectedId = event['featureData']['id'];
            this.setState({ sheetOpened: true });
        });

        setTimeout(() => {
            this.closeLoadingDialog();
        }, 300);
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CONTENT_KML);
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_KML, this.menuItem.title, this.menuItem.id);

        const self = this;
        this.map = this.createGoogleMap(self);

        if (this.menuItem['attchmUrl']) {
            this.openLoadingDialog();
            const parseKML = require('parse-kml');
            parseKML
                .toJson(this.menuItem['attchmUrl'])
                .then((json) => { this.parseKML(json) })
                .catch(console.error);
        }
    }

    registerAnalyticsEvent = (eventName, kmlTitle, kmlId) => {
        if (kmlTitle && kmlId)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: kmlTitle,
                    ContentId: kmlId,
                });
    }

    openLoadingDialog() {
        this.$f7.dialog.preloader(localeStrings.loading);
        setTimeout(() => {
            this.$f7.dialog.close();
        }, 10000);
    }

    closeLoadingDialog() {
        this.$f7.dialog.close();
    }

    createGoogleMap = (self) => {
        var map = new window.google.maps.Map(document.getElementById(self.getMapId(this.menuItem)), {
            center: { lat: 44.6981764, lng: 10.6288892 },
            zoom: 14,
            disableDefaultUI: true,
        });

        return map;
    }

    getMapId = (menuItem) => {
        return 'map_' + menuItem['id'];
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentKML);