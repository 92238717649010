import React from 'react';

class ImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spans: 0 };
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        this.imageRef.current.addEventListener("load", this.setSpans);
    }

    setSpans = () => {
        if (this.imageRef.current) {
            let height = this.imageRef.current.clientHeight + 70;
            let spans = Math.ceil(height / 10);
            this.setState({ spans: spans });
        }
    }

    render() {
        return (
            <div id={this.props.id} className="card" style={{ gridRowEnd: `span ${this.state.spans}` }}>
                <div className="card-content" onClick={this.props.modifyView}>
                    <div>
                        {this.props.image ?
                            <img
                                ref={this.imageRef}
                                src={this.props.image}
                                alt="Main view"
                                width="100%"
                                className="lazy lazy-fade-in" />
                            :
                            <img
                                ref={this.imageRef}
                                src={'./img/discover_img_placeholder.jpg'}
                                alt="Main view"
                                width="100%"
                                className="lazy lazy-fade-in" />
                        }
                    </div>
                </div>
                <div className="card-footer">
                    <div className="card-footer-icon-container">
                        <div>
                            <span className="material-icons">star</span>
                            <p>{this.props.rating}</p>
                        </div>
                        <div>
                            <p>{this.props.keyPointsCount}</p>
                            <span className="material-icons">adjust</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageCard;