import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        ar_involve_title: "AR INVOLVE 3D",
        ar_planar_title: "AR VIEW",
        first_tutorial_text: "Tutorial\nAR INVOLVE 3D",
        second_tutorial_text: "Tutorial\nAR VIEW",
    },
    it: {
        ar_involve_title: "AR INVOLVE 3D",
        ar_planar_title: "AR VIEW",
        first_tutorial_text: "Tutorial\nAR INVOLVE 3D",
        second_tutorial_text: "Tutorial\nAR VIEW",
    }
});

export default localeStrings;