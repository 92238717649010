import * as JSEncrypt from 'jsencrypt';

export class PkCryptoRSA {

    /*
    * Encrypt with RSA
    */
    static encryptRSA = (publicRSAKey, stringToEnc) => {

        var encryptRSA = new JSEncrypt.JSEncrypt();
        encryptRSA.setPublicKey(publicRSAKey);
        var encrypted = encryptRSA.encrypt(stringToEnc);
        return encrypted;
    }

    /*
    * Decrypt with RSA
    */
    static decryptRSA = (privateRSAKey, stringToDec) => {

        var decryptRSA = new JSEncrypt.JSEncrypt();
        decryptRSA.setPrivateKey(privateRSAKey);
        var passphrase = decryptRSA.decrypt(stringToDec);
        return passphrase;
    }

}
export default PkCryptoRSA;