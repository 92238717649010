import React from 'react';
import { connect } from 'react-redux';
import { Page, Navbar, Block, PageContent } from 'framework7-react';
import Commons from '../commons'
import ContentListItem from './content-list-item';
import PikkartNavbar from '../components/pk-navbar/pk-navbar';

class MenuList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let item = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={item.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={item.catIndex > 1} />
                </Navbar>

                {this.getItemList(item)}
            </Page>
        )
    }

    getItemList = (item) => {

        const itemData = item.items;
        const ret = itemData.map((item, index) => {

            return (<ContentListItem
                contentData={{ content: item }}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListItem>);
        });

        return (
            <Block style={{ padding: '0' }}>
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>)
    }

    onCardClick = (item) => {
        //this.$f7router.navigate(Commons.getMenuItemUrl(item.content));
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);