import { PkCryptoAES } from "./crypto/aes";
import { PkCordova } from "./cordova/index";
import { PkCommons } from "./commons";

export class PkStorage {
  static setToken = (token) => {
    //if (PkCordova.isCordova())
    localStorage.setItem("token", token);
    /*else
        sessionStorage.setItem('token', token);*/
  };

  static deleteToken = () => {
    //if (PkCordova.isCordova())
    localStorage.removeItem("token");
    /*else
        sessionStorage.removeItem('token');*/
  };

  static deletePrivateKey = () => {
    localStorage.removeItem("pk");
    sessionStorage.removeItem("pk");
  };

  static setPrivateKey = (pk) => {
    localStorage.setItem("pk", pk);
  };

  static getPrivateKey = () => {
    let pk = sessionStorage.getItem("pk");
    if (!pk) {
      pk = localStorage.getItem("pk");
      let profile = PkStorage.getProfile();
      if (pk && profile) {
        try {
          pk = PkCryptoAES.decryptAES(pk, profile.UniqueId);
          sessionStorage.setItem("pk", pk);
        } catch {
          pk = null;
        }
      }
    }
    return pk;
  };

  static getToken = () => {
    //if (PkCordova.isCordova())
    return localStorage.getItem("token");
    /*else
        return sessionStorage.getItem('token');*/
  };

  static setFirebaseToken = (token) => {
    if (PkCordova.isCordova()) {
      if (token) {
        localStorage.setItem("firebaseToken", token);
      } else {
        localStorage.removeItem("firebaseToken");
      }
    }
  };

  static getFirebaseToken = () => {
    if (PkCordova.isCordova()) return localStorage.getItem("firebaseToken");
    else return null;
  };

  static setVoipToken = (token) => {
    if (PkCordova.isCordova()) {
      if (token) {
        localStorage.setItem('voipToken', token);
      }
      else {
        localStorage.removeItem('voipToken');
      }
    }
  }

  static getVoipToken = () => {
    if (PkCordova.isCordova())
      return localStorage.getItem('voipToken');
    else
      return null;
  }

  static getProfile = () => {
    //if (PkCordova.isCordova()) {
    if (localStorage.getItem("profile") != null)
      return JSON.parse(localStorage.getItem("profile"))[0];
    else return null;
    /*}
        if (sessionStorage.getItem('profile') != null)
            return eval(sessionStorage.getItem('profile'))[0];
        else
            return null;*/
  };

  static setRecents = (recents) => {
    return localStorage.setItem("recents", JSON.stringify(recents));
  };

  static getRecents = () => {
    if (localStorage.getItem("recents") != null)
      return JSON.parse(localStorage.getItem("recents"));
    else return null;
  };

  static doLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  static setRooms = (rooms) => {
    return localStorage.setItem("rooms", JSON.stringify(rooms));
  };

  static getRooms = () => {
    if (localStorage.getItem("rooms") != null)
      return JSON.parse(localStorage.getItem("rooms"));
    else return null;
  };

  static deleteRoom = (roomId) => {
    localStorage.removeItem("room_" + roomId);
  };

  static addMessage = (roomId, message) => {
    let messages = PkStorage.getMessages(roomId);
    //il messaggio si aggiunge solo se ce ne sono già, perché significa che sono stati caricati almeno una volta
    if (messages && messages.length > 0) {
      PkCommons.insertAt(messages, 0, message);
      PkStorage.setMessages(roomId, messages);
    }
  };

  static setMessages = (roomId, messages) => {
    return localStorage.setItem("room_" + roomId, JSON.stringify(messages));
  };

  static getMessages = (roomId) => {
    if (localStorage.getItem("room_" + roomId) != null)
      return JSON.parse(localStorage.getItem("room_" + roomId));
    else return null;
  };

  static setProfile = (profile) => {
    //if (PkCordova.isCordova())
    return localStorage.setItem("profile", "[" + JSON.stringify(profile) + "]");
    /*else
            return sessionStorage.setItem('profile', '[' + JSON.stringify(profile) + ']');*/
  };

  static setLoginCompany = (company) => {
    //if (PkCordova.isCordova())
    return localStorage.setItem("company", "[" + JSON.stringify(company) + "]");
    /*else
            return sessionStorage.setItem('company', '[' + JSON.stringify(company) + ']');*/
  };
  static getLoginCompany = () => {
    //if (PkCordova.isCordova()) {
    if (localStorage.getItem("company") != null)
      return JSON.parse(localStorage.getItem("company"))[0];
    else return null;
    /*}
        if (sessionStorage.getItem('company') != null)
            return eval(sessionStorage.getItem('company'))[0];
        else
            return null;*/
  };

  static deleteLoginCompany = () => {
    //if (PkCordova.isCordova())
    localStorage.removeItem("company");
    /*else
            sessionStorage.removeItem('company');*/
  };

  static deleteProfile = () => {
    //if (PkCordova.isCordova())
    return localStorage.removeItem("profile");
    /*else
            return sessionStorage.removeItem('profile');*/
  };

  static setCurrentCallTimer = (value) => {
    sessionStorage.setItem("callTimer", value);
  };
  /*
        static setAppCustomerCode = (code) => {
            if(PkCordova.isCordova())
                return localStorage.setItem('customerCode', code);
            else
                return sessionStorage.setItem('customerCode', code);
        };
    
        static getAppCustomerCode = () => {
            if(PkCordova.isCordova())
                return localStorage.getItem('customerCode');
            else
                return sessionStorage.getItem('customerCode');
        };
    */
  static getCurrentCallTimer = () => {
    return sessionStorage.getItem("callTimer");
  };

  static getClosedCallsIds = () => {
    let closedCallsIds = sessionStorage.getItem("closedCallsIds");
    if (closedCallsIds != null)
      return JSON.parse(sessionStorage.getItem("closedCallsIds"));
    else return [];
  };

  static addClosedCallId = (callId) => {
    const callIds = PkStorage.getClosedCallsIds();
    let callToSave = [];
    callToSave.push(callId);

    if (callIds !== null) {
      callIds.forEach((element) => {
        if (element !== callId) {
          callToSave.push(element);
        }
      });
    }
    sessionStorage.setItem("closedCallsIds", JSON.stringify(callToSave));
  };

  static setMediaInputVideo = (deviceId) => {
    if (!deviceId) {
      localStorage.removeItem("mediaInputVideo");
    } else {
      localStorage.setItem("mediaInputVideo", deviceId);
    }
  };

  static setMediaInputVideoAR = (deviceId) => {
    if (!deviceId) {
      localStorage.removeItem("mediaInputVideoAR");
    } else {
      localStorage.setItem("mediaInputVideoAR", deviceId);
    }
  };

  static setMediaInputAudio = (deviceId) => {
    localStorage.setItem("mediaInputAudio", deviceId);
  };

  static setMediaOutputAudio = (deviceId) => {
    localStorage.setItem("mediaOutputAudio", deviceId);
  };

  static setArVideoInputDeviceEnable = (enable) => {
    localStorage.setItem(
      "mediaInputVideoArEnabled",
      enable === true ? "true" : "false"
    );
  };

  static getMediaInputVideo = () => {
    return localStorage.getItem("mediaInputVideo");
  };

  static getMediaInputVideoAR = () => {
    return localStorage.getItem("mediaInputVideoAR");
  };

  static getMediaInputAudio = () => {
    return localStorage.getItem("mediaInputAudio");
  };

  static getMediaOutputAudio = () => {
    return localStorage.getItem("mediaOutputAudio");
  };

  static isArVideoInputDeviceEnable = () => {
    return localStorage.getItem("mediaInputVideoArEnabled") === "true";
  };
}
export default PkStorage;
