import { PkStore } from "../../redux/store";
import { PkReduxActions } from "../../redux/actions";
import { PkApiNet } from "../../api/net";
import { PkApiRoomsMessages } from "../../api/rooms/messages";
import { PkApiCallsCommunicationProvider } from "../../api/calls/communicationProvider";
import { PkCrypto } from "../../crypto/crypto";
import { PkApiContacts } from "../../api/contacts";
import { PkStorage } from "../../storage";
import { Pk } from "../../index";
/*
const Type = Object.freeze({
  MSG: Symbol("pic"),
  PIC: Symbol("msg"),
});

class DataMessage {
  constructor(type, data) {
    this._type = type;
    this._data = data;
  }

  get type() {
    return this._type;
  }
  get data() {
    return this._data;
  }
}*/

export class PkApiCallsMessages {
  static getMessages = (callUniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          callUniqueId +
          "/messages",
      })
        .then(function (response) {
          if (response.result.success === true) {
            response.data.forEach((message) => {
              message = PkApiRoomsMessages.GetDecryptedMessage(message);
            });
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getOnlineMessages = () => {
    return new Promise((resolve, reject) => {
      PkApiCallsMessages.getMessages(
        PkStore.getState().activeCall.Call.UniqueId
      )
        .then(function (response) {
          if (response.result.success === true) {
            response.data.forEach((message) => {
              message = PkApiRoomsMessages.GetDecryptedMessage(message);
            });
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static GetDecryptedMessage = (message) => {
    message.Message = PkCrypto.decrypt(message.Message, message.CryptoSchema);
    if (!message.Message) {
      message.Message = "";
    }

    return message;
  };

  static sendMessage = (message, file) => {
    return new Promise((resolve, reject) => {
      let recipients = PkStore.getState().activeCallParticipants;
      recipients.push(
        PkApiContacts.getContactIdentities(
          PkStorage.getProfile().UniqueId,
          PkStorage.getProfile().PublicKey
        )
      );

      const bodyFormData = new FormData();
      bodyFormData.set("message", PkCrypto.encrypt(message, recipients));
      if (file) {
        let headerSeparator = file.base64.indexOf(",");
        if (headerSeparator) {
          file.base64 = file.base64.substring(headerSeparator + 1);
        }
        bodyFormData.set(
          "base64file",
          PkCrypto.encrypt(file.base64, recipients)
        );
        bodyFormData.set("fileName", file.fileName);
      }
      bodyFormData.set("cryptoschema", Pk.getConfig().cryptoSchema);

      PkApiNet.callApi({
        method: "post",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          PkStore.getState().activeCall.Call.UniqueId +
          "/messages",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkApiCallsCommunicationProvider.sendMessage(
              '{"type": "msg", "data": ' + JSON.stringify(response.data) + " }"
            );

            response.data = PkApiCallsMessages.GetDecryptedMessage(
              response.data
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static clearUnreadMessages = () => {
    PkStore.get().dispatch(PkReduxActions.setUnreadMessages([]));
  };
}

export default PkApiCallsMessages;
