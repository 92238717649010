import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, PageContent, List, ListItem } from 'framework7-react';
import Commons from '../../commons';
import appLocaleStrings from '../../app-local';
import { PkCordovaNotifications, PkCordova } from 'pikkart-cordova';
import * as actions from '../../redux/actions';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';

class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            notificationsData: null,
            receivedNotifications: []
        };
    }

    render() {

        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={menuItem.catIndex > 1} />
                </Navbar>
                <div>
                    {this.getNotificationsData()}
                </div>

            </Page>
        )
    }

    getNotificationsData = () => {
        if (this.state.notificationsData === null || this.state.notificationsData.length === 0) {
            if (this.state.errorMessage) {
                return (

                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.notificationsData.map((item, index) => {
            return (
                <ListItem
                    key={index}
                    title={item.title}
                    after={item.date}
                    text={item.descr} />
            );
        });

        return (
            <Block style={{ padding: '0' }}>
                <PageContent style={{ padding: '0' }}><List mediaList>{ret}</List></PageContent>
            </Block>)
    }

    componentDidMount = () => {
        this.showPreloader(true);

        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.NOTIFICATIONS);

        if (this.state.notificationsData === null) {
            this.notificationsData();
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.receivedNotifications !== this.state.receivedNotifications) {
            this.notificationsData();

            this.setState({ receivedNotifications: this.props.receivedNotifications })
        }
    }

    onMessageReceived = (message) => {

        this.showPreloader(true);

        this.notificationsData();
    }

    notificationsData = () => {

        const errorFn = (errorMsg) => {
            this.setState({
                errorMessage: errorMsg,
                notificationsData: null
            });

            this.showPreloader(false);
        }

        const successFn = (notificationsData) => {
            this.setState({ notificationsData: notificationsData, errorMessage: null });

            this.showPreloader(false);
        }//END TODO

        const self = this;

        if (PkCordova.isCordova()) {
            PkCordovaNotifications.getReceivedMessages().then(function (messages) {
                if (!messages || 0 === messages.length) {
                    errorFn(appLocaleStrings.no_contents);
                }
                else {
                    self.props.setReceivedNotifications(messages);

                    if (messages != null) {
                        if (JSON.parse(messages).length === 0)
                            errorFn(appLocaleStrings.no_contents);
                        else
                            successFn(JSON.parse(messages));
                    } else {
                        errorFn("ERRORE");
                    }
                }
            });
        }
        else {
            errorFn(appLocaleStrings.no_contents);
        }

        /*

          const formattedDate = new Date(payload["google.sent_time"]).toDateString();
          const [, month, day, year] = formattedDate.split(' ')
          const dateDdMmmYyyy = [day, month, year].join('/')

          let notificationsData = JSON.parse(localStorage.getItem("notifications_data"));

          if (!notificationsData) {
            notificationsData = [];
          }

          notificationsData.push({ date: dateDdMmmYyyy, title: payload.title, text: payload.text });

          localStorage.setItem("notifications_data", JSON.stringify(notificationsData));
*/
    };

    showPreloader = (show) => {
        if (show) {
            this.$f7.preloader.show();
        }
        else {
            this.$f7.preloader.hide();
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        receivedNotifications: state.app.receivedNotifications
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setReceivedNotifications: (receivedNotifications) => dispatch(actions.setReceivedNotifications(receivedNotifications)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);