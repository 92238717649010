import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Tutorial",
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start",
        tutorial_1: "Point the camera towards a flat surface and move the device around",
        tutorial_2: "Confirm the placement of the product",
        tutorial_3: "Interact with the product and its added content",
    },
    it: {
        title: "Tutorial",
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia",
        tutorial_1: "Entra in Home e seleziona dal menu il logo con l'esperienza",
        tutorial_2: "Inquadra l'immagine in cui vedi il marker AR+",
        tutorial_3: "Guarda il contenuto multimediale e accedi ai contenuti informativi",
    }
});

export default localeStrings;