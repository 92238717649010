import Pk from '../index';
import PkApiNet from '../api/net';

export class PkArMarkers {
    static syncCloudMarkers = (userId, place) => {
        return new Promise((resolve, reject) => {
            let cmsPlace = 0;
            if (place)
                cmsPlace = place;

            PkApiNet.callApi({
                method: 'post',
                url: Pk.getConfig().apiUrl + '/api/markers?place=' + cmsPlace,
                data: {
                    userId: userId
                },
                config: {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            }).then(function (response) {
                resolve(response);
            }).catch(function (errorMessage) {
                reject(new Error(errorMessage));
            });
        });
    }
}

export default PkArMarkers;