import React from 'react';
import { connect } from 'react-redux';
import { f7, Page, Block, Row, Col } from 'framework7-react';
import * as actions from '../../redux/actions';
import { PkCordova, PkCordovaBeaconReceiver } from 'pikkart-cordova';
import { DateTime } from 'luxon';
import Commons from '../../commons'

import { PkLog } from '../../pikkart-cms/log';
import { PkStorage } from '../../pikkart-cms/storage';
import { PkNotifications } from '../../pikkart-cms/notifications';
import { PkContents, TRIGGER_SOURCE_TYPE } from '../../pikkart-cms/contents';
import { Pk } from '../../pikkart-cms/index';

class Splash extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <Page style={{ backgroundColor: process.env.REACT_APP_SPLASH_SCREEN_BACKGROUND_COLOR }}>
        <Block textAlign="center" >
          <Row style={{ width: '100%' }}>
            <Col style={{ textAlign: 'center' }}>
              <img src={'./img/logo/logo_splash.png'} alt="logo" width="120" />
            </Col>
          </Row>
        </Block>
      </Page>
    )
  }

  componentDidMount = () => {
    //  const self = this;
    this.showPreloader(true);

    if (PkCordova.isCordova()) {
      if (PkStorage.getFirebaseToken() != null) {
        PkLog.log("sending FIREBASE TOKEN to server");
        PkNotifications.sendTokenToServer(PkStorage.getFirebaseToken())
          .then(function () {
            PkLog.log("FIREBASE TOKEN to server: success " + PkStorage.getFirebaseToken());
          })
          .catch(function () {
            PkLog.log("FIREBASE TOKEN to server: error");
          });
      } else {
        PkLog.log("no FIREBASE TOKEN to send");
      }

      //this.getBeaconList();
    }

    this.fetchContentsMenu();
  }

  startBeaconService = (beacons) => {
    const apiUrl = Pk.getConfig().apiUrl
    const authorization = Pk.getConfig().apiKey + ":" + Pk.getConfig().deviceId;
    const pkDate = DateTime.fromMillis(Date.now()).toUTC().toFormat("y-LL-dd'T'HH:mm:ss");
    if (beacons.length > 0) {
      PkCordovaBeaconReceiver.startBeaconSearch(apiUrl, authorization, pkDate, beacons);
    }
    else {
      PkLog.error("No beacons found");
    }
  }

  getBeaconList = () => {
    const self = this;
    PkContents.getTriggersList(TRIGGER_SOURCE_TYPE.BEACONS_SOURCE_TYPE)
      .then((response) => {
        if (response.result.success === true) {
          self.startBeaconService(response.data);
        } else {
          PkLog.log('Trigger list success: ', response.result.message);
        }
      })
      .catch(function (response) {
        PkLog.error('Trigger list error: ' + response.message);
      });
  }

  fetchContentsMenu = () => {
    localStorage.setItem('menu', process.env.REACT_APP_DEFAULT_MENU_NO_CONNECTION);

    PkContents.getContentsMenu().then((contentsMenu) => {
      if (contentsMenu.data)
        localStorage.setItem('menu', JSON.stringify(contentsMenu.data));
    })
      .catch((err) => {
        PkLog.error('load menu error', err);
      })
      .then(() => {
        let menu = [];
        let menuStr = localStorage.getItem('menu');
        if (menuStr != null) {
          menu = JSON.parse(localStorage.getItem('menu'));
        }
        else {
          PkLog.error('load menu error');
        }
        this.props.setMenu(menu);
        this.showPreloader(false);


        //app non reggio
        let menuItems = this.props.menu ? this.props.menu : [];
        let path = process.env.REACT_APP_DEFAULT_CODE_PAGE_NO_CONNECTION;
        if (menuItems) {
          menuItems.forEach(item => {
            if (item.code === process.env.REACT_APP_DEFAULT_CODE_PAGE)
              path = Commons.getMenuItemUrl(item)
          });
        }
        this.props.f7router.navigate(path, { reloadAll: true });
      });
  }

  showPreloader = (show) => {
    if (show) {
      f7.preloader.show();
    }
    else {
      f7.preloader.hide();
    }
  }
};


// #region Redux
const mapStateToProps = state => {
  return {
    menu: state.app.menu
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenu: (menu) => dispatch(actions.setMenu(menu)),
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Splash);