import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start",
        tutorial_1: "Point the camera at artifacs inside the museum",
        tutorial_2: "You will access exclusive digital content: videos, image galleries, information cards and much more ...!",
        tutorial_3: "Interact with the product and its added content",
        loading_text: "Loading scene",
        scene_loading_error_message: "Error loading scene",
        media_loading_error_message: "Error loading media",
    },
    it: {
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia",
        tutorial_1: "Inquadra gli artefatti all'interno del museo",
        tutorial_2: "Accederai a contenuti digitali esclusivi: video, gallerie di immagini, schede informative e molto altro...!",
        tutorial_3: "Interagisci con il prodotto e i suoi contenuti aggiuntivi",
        loading_text: "Caricamento scena",
        scene_loading_error_message: "Errore caricamento scena",
        media_loading_error_message: "Errore caricamento media",
    }
});

export default localeStrings;