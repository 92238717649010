import React from 'react';
import { connect } from 'react-redux';
import { f7, Page, Navbar } from 'framework7-react';
import localeStrings from './ar-local';
import { PkCordovaARSdk, PkCordovaPermissions } from 'pikkart-cordova';
import Commons from '../../commons';
import * as actions from '../../redux/actions';
import PikkartNavbar from '../../components//pk-navbar/pk-navbar';
import { PkLog } from '../../pikkart-cms/log';
import TutorialHelper from '../../helpers/tutorial-helper';

class Ar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTutorial: false,
        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        const tutorial = (this.state.showTutorial ?
            TutorialHelper.getArTutorial(this.toggleClicked, this.startMarkerRecognition)
            : null)

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem ? menuItem.title : ""}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                {tutorial}
            </Page>
        )
    }

    componentDidMount = () => {


        if (this.props.f7route.path.substring(this.props.f7route.path.lastIndexOf('/') + 1).startsWith("mailto:")) {
            const mailTo = this.props.f7route.path.substring(this.props.f7route.path.lastIndexOf('/') + 1);
            window.open(encodeURI(mailTo), '_system')
        }
        else {
            const skippedArLogoTutorial = JSON.parse(localStorage.getItem('skip_ar_logo_tutorial'));
            if (skippedArLogoTutorial) {
                this.props.setSkippedArLogoTutorial(skippedArLogoTutorial);

                this.startMarkerRecognition(this.startMarkerRecognitionCallback);
            }
            else {
                this.setState({ showTutorial: true })
            }
        }
    }

    toggleClicked = (selected) => {
        this.props.setSkippedArLogoTutorial(selected);
        localStorage.setItem('skip_ar_logo_tutorial', selected);
    }

    startMarkerRecognitionCallback = () => {

        let menuItems = this.props.menu ? this.props.menu : [];
        let path = '/content-list/';
        menuItems.forEach(item => {
            if (item.code === process.env.REACT_APP_REDIRECT_CODE_PAGE)
                path = Commons.getMenuItemUrl(item)
        });

        this.props.f7router.navigate(path, { reloadAll: true });
    }

    startMarkerRecognition = (deviceId, callback) => {
        const self = this;
        let _dialog = null;
        const startRecognitionFragment = () => {//apiUrl, cmsContentPageUrl, enableGlobalRecognition
            self.props.setActivityPaused(true);
            PkCordovaARSdk.startRecognition('https://ws.ar-9.pikkart.com', "?contentid=", false)
                .then(() => {
                    PkLog.log('Marker recognition success');
                    self.startMarkerRecognitionCallback()
                })
                .catch(() => {
                    PkLog.error('Marker recognition error');
                });
        }

        const downloadMarkers = () => {
            PkCordovaPermissions.askStoragePermission().then((status) => {
                openDeterminedProgress();
                PkCordovaARSdk.downloadMarkers('https://ws.ar-9.pikkart.com', -1, finished, error, progress);
            }).catch((err) => {
                PkLog.error(err);
                openErrorDialog(err);
            });

            const finished = () => {
                PkLog.log('Download markers finished');
                _dialog.setText(localeStrings.loading_markers_complete);

                closeDialog(false);
                startRecognitionFragment();
            };

            const progress = (progressValue) => {
                PkLog.log('Download markers progress ' + progressValue);
                if (_dialog)
                    _dialog.setProgress(progressValue);
                Math.round(progressValue)
                _dialog.setText(localeStrings.formatString(localeStrings.loading_markers_percentage, progressValue));
            };

            const error = () => {
                _dialog.setText(localeStrings.loading_markers_error);
                closeDialog(true);
            };

            const openDeterminedProgress = () => {
                const app = f7;
                let progress = 0;
                _dialog = app.dialog.progress(localeStrings.download_title, progress);
                _dialog.setText(localeStrings.download_text);
            }

            const openErrorDialog = (error) => {
                PkLog.error(error);
                const app = f7;
                _dialog = app.dialog.alert(localeStrings.ar_error_text, () => {
                    if (callback) {
                        callback();
                    }
                });
                _dialog.setTitle(localeStrings.ar_error_title);

            }
        }

        const closeDialog = (redirect) => {
            setTimeout(function () {
                _dialog.close();

                if (callback && redirect) {
                    callback();
                }

            }, this.state.dialogClosingTime)
        }

        downloadMarkers();
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        skippedArLogoTutorial: state.app.skippedArLogoTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSkippedArLogoTutorial: (skippedArLogoTutorial) => dispatch(actions.setSkippedArLogoTutorial(skippedArLogoTutorial)),
        setActivityPaused: (activityPaused) => dispatch(actions.setActivityPaused(activityPaused)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Ar);