
import appActionTypes from './actionTypes';

const initialState = {
    orientation: null,
    notificationServiceError: null,
    menu: [],
    skippedArLogoTutorial: false,  //true: skip tutorial     false: see tutorial
    skippedDiscoverTutorial: false,  //true: skip tutorial     false: see tutorial
    skippedArSceneTutorial: false,  //true: skip tutorial     false: see tutorial
    skippedPlateSearchTutorial: false, //true: skip tutorial     false: see tutorial
    receivedNotifications: [],
    favoriteContents: [],
    acceptedTermsOfUse: false,
    imageCroppingCompleted: false,
    focusQualityTolerance: 130,
    chosenResolutionIndex: 2,
    activityPaused: false,
    currentFilteredCustomer: null,
    onlyPrivateContentsVisible: true,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case appActionTypes.SET_ORIENTATION:
            return {
                ...state,
                orientation: action.orientation,
            }
        case appActionTypes.SET_NOTIFICATION_SERVICE_ERROR:
            return {
                ...state,
                notificationServiceError: action.notificationServiceError,
            }
        case appActionTypes.SET_MENU:
            return {
                ...state,
                menu: action.menu,
            }
        case appActionTypes.SET_SKIPPED_AR_LOGO_TUTORIAL:
            return {
                ...state,
                skippedArLogoTutorial: action.skippedArLogoTutorial,
            }
        case appActionTypes.SET_SKIPPED_DISCOVER_TUTORIAL:
            return {
                ...state,
                skippedDiscoverTutorial: action.skippedDiscoverTutorial,
            }
        case appActionTypes.SET_SKIPPED_AR_SCENE_TUTORIAL:
            return {
                ...state,
                skippedArSceneTutorial: action.skippedArSceneTutorial,
            }
        case appActionTypes.SET_SKIPPED_PLATE_SEARCH_TUTORIAL:
            return {
                ...state,
                skippedPlateSearchTutorial: action.skippedPlateSearchTutorial,
            }
        case appActionTypes.SET_RECEIVED_NOTIFICATIONS:
            return {
                ...state,
                receivedNotifications: action.receivedNotifications,
            }
        case appActionTypes.SET_FAVORITE_CONTENTS:
            return {
                ...state,
                favoriteContents: action.favoriteContents,
            }
        case appActionTypes.SET_ACCEPTED_TERMS_OF_USE:
            return {
                ...state,
                acceptedTermsOfUse: action.acceptedTermsOfUse,
            }
        case appActionTypes.SET_IMAGE_CROPPING_COMPLETED:
            return {
                ...state,
                imageCroppingCompleted: action.imageCroppingCompleted,
            }
        case appActionTypes.SET_FOCUS_QUALITY_TOLERCANCE:
            return {
                ...state,
                focusQualityTolerance: action.focusQualityTolerance,
            }
        case appActionTypes.SET_CHOSEN_RESOLUTION_INDEX:
            return {
                ...state,
                chosenResolutionIndex: action.chosenResolutionIndex,
            }
        case appActionTypes.SET_ACTIVITY_PAUSED:
            return {
                ...state,
                activityPaused: action.activityPaused,
            }
        case appActionTypes.SET_CURRENT_FILTERED_CUSTOMER:
            return {
                ...state,
                currentFilteredCustomer: action.currentFilteredCustomer,
            }
        case appActionTypes.SET_ONLY_PRIVATE_CONTENTS_VISIBLE:
            return {
                ...state,
                onlyPrivateContentsVisible: action.onlyPrivateContentsVisible,
            }
        default:
    }
    return state;
};

export default appReducer;