import React from 'react';
import { connect } from 'react-redux';
import { f7, TextEditor, Navbar, Page, Block, List, ListInput, Button, ListItem } from 'framework7-react';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Commons from '../../commons';
import localeStrings from './contact-us-local'
import axios from 'axios';

class ContactUs extends React.Component {

    _name = "";
    _surname = "";
    _email = "";
    _city = "";
    _zip = "";
    _province = "";
    _country = "";
    _profession = "";
    _phone = "";
    // _mobilePhone = "";
    _message = this.props.precompiledNoteInfo ? this.props.precompiledNoteInfo : "";

    constructor(props) {
        super(props);

        this.state = {
            privacy_checked: false,
            newsletter_checked: false
        };
    }

    render() {
        const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        const title = menuItem ? menuItem.title : "Contact us";
        let showNavRight = this.props.showNavRight ? this.props.showNavRight : process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR;
        let showArrowAsNavRight = this.props.showArrowAsNavRight ? this.props.showArrowAsNavRight : false;

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={title}
                        logo='./img/logo/logo.png'
                        showNavRight={showNavRight}
                        showArrowAsNavRight={showArrowAsNavRight} />
                </Navbar>
                <Block>
                    <List noHairlinesMd form formStoreData id="input_container">
                        <ListInput
                            className="contact-form-input"
                            id="name"
                            floatingLabel={true}
                            label={localeStrings.name}
                            type="text"
                            placeholder={localeStrings.name_placeholder}
                            info={localeStrings.required_validation_text}
                            errorMessage={localeStrings.required_validation_empty_text}
                            onInput={(value) => {
                                this._name = value.target.value;
                            }}
                            required
                            validate
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="surname"
                            floatingLabel={true}
                            label={localeStrings.surname}
                            type="text"
                            placeholder={localeStrings.surname_placeholder}
                            info={localeStrings.required_validation_text}
                            errorMessage={localeStrings.required_validation_empty_text}
                            onInput={(value) => {
                                this._surname = value.target.value;
                            }}
                            required
                            validate
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="email"
                            floatingLabel={true}
                            label={localeStrings.email}
                            type="email"
                            placeholder={localeStrings.email_placeholder}
                            info={localeStrings.required_validation_text}
                            errorMessage={localeStrings.email_format_wrong_text}
                            onInput={(value) => {
                                this._email = value.target.value;
                            }}
                            required
                            validate
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="city"
                            floatingLabel={true}
                            label={localeStrings.city}
                            type="text"
                            placeholder={localeStrings.city_placeholder}
                            info={localeStrings.required_validation_text}
                            errorMessage={localeStrings.required_validation_empty_text}
                            onInput={(value) => {
                                this._city = value.target.value;
                            }}
                            required
                            validate
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="zip"
                            floatingLabel={true}
                            label={localeStrings.address_zip}
                            type="text"
                            placeholder={localeStrings.address_zip_placeholder}
                            errorMessage={localeStrings.format_wrong_text}
                            onInput={(value) => {
                                this._zip = value.target.value;
                            }}
                            validate
                            pattern="[0-9][0-9][0-9][0-9][0-9]"
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="province"
                            floatingLabel={true}
                            label={localeStrings.province}
                            type="text"
                            placeholder={localeStrings.province_placeholder}
                            onInput={(value) => {
                                this._province = value.target.value;
                            }}
                            clearButton
                        />
                        <ListInput
                            className="contact-form-input"
                            id="country"
                            label={localeStrings.country}
                            type="select"
                            placeholder={localeStrings.country_placeholder}
                            required
                            errorMessage={localeStrings.required_validation_empty_list_text}
                            onInput={(value) => {
                                this._country = value.target.value;
                            }}
                        >
                            <option value="">-</option>
                            <option value="IT">Italia</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="SA">Arabia Saudita</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BE">Belgio</option>
                            <option value="BO">Bolivia</option>
                            <option value="BW">Botswana</option>
                            <option value="BR">Brasile</option>
                            <option value="BG">Bulgaria</option>
                            <option value="CA">Canada</option>
                            <option value="CL">Cile</option>
                            <option value="CN">Cina</option>
                            <option value="CY">Cipro</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comore</option>
                            <option value="KR">Corea del Sud</option>
                            <option value="CI">Costa d'avorio</option>
                            <option value="CR">Costa Rica</option>
                            <option value="HR">Croazia</option>
                            <option value="CU">Cuba</option>
                            <option value="DK">Danimarca</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egitto</option>
                            <option value="SV">El Salvador</option>
                            <option value="AE">Emirati Arabi Uniti</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Etiopia</option>
                            <option value="FJ">Figi</option>
                            <option value="FI">Finlandia</option>
                            <option value="FR">Francia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germania</option>
                            <option value="GH">Ghana</option>
                            <option value="JP">Giappone</option>
                            <option value="JO">Giordania</option>
                            <option value="GR">Grecia</option>
                            <option value="GT">Guatemala</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="GB">Inghilterra</option>
                            <option value="IR">Iran</option>
                            <option value="IE">Irlanda</option>
                            <option value="IS">Islanda</option>
                            <option value="IL">Israele</option>
                            <option value="KZ">Kazakistan</option>
                            <option value="KE">Kenia</option>
                            <option value="LV">Lettonia</option>
                            <option value="LB">Libano</option>
                            <option value="LY">Libia</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lituania</option>
                            <option value="LU">Lussemburgo</option>
                            <option value="MK">Macedonia</option>
                            <option value="MY">Malaysia</option>
                            <option value="MT">Malta</option>
                            <option value="MO">Marocco</option>
                            <option value="MX">Messico</option>
                            <option value="NG">Nigeria</option>
                            <option value="NO">Norvegia</option>
                            <option value="NZ">Nuova Zelanda</option>
                            <option value="NL">Olanda</option>
                            <option value="PK">Pakistan</option>
                            <option value="PA">Panama</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Perù</option>
                            <option value="PL">Polonia</option>
                            <option value="PT">Portogallo</option>
                            <option value="MC">Principato di Monaco</option>
                            <option value="QA">Qatar</option>
                            <option value="CZ">Repubblica Ceca</option>
                            <option value="SM">Repubblica di San Marino</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russia</option>
                            <option value="SN">Senegal</option>
                            <option value="ME">Serbia Montenegro</option>
                            <option value="SG">Singapore</option>
                            <option value="SY">Siria</option>
                            <option value="SK">Slovacchia</option>
                            <option value="SI">Slovenia</option>
                            <option value="ES">Spagna</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="US">Stati Uniti d'America</option>
                            <option value="ZA">Sud Africa</option>
                            <option value="SE">Svezia</option>
                            <option value="CH">Svizzera</option>
                            <option value="TH">Tailandia</option>
                            <option value="TW">Taiwan</option>
                            <option value="TZ">Tanzania</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turchia</option>
                            <option value="UA">Ucraina</option>
                            <option value="HU">Ungheria</option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VE">Venezuela</option>
                            <option value="VN">Vietnam</option>
                            <option value="YE">Yemen</option>
                        </ListInput>
                        <ListInput
                            className="contact-form-input"
                            id="profession"
                            label={localeStrings.profession}
                            type="select"
                            placeholder={localeStrings.profession_placeholder}
                            onInput={(value) => {
                                this._profession = value.target.value;
                            }}
                            required
                            errorMessage={localeStrings.required_validation_empty_list_text}
                        >
                            <option value="">-</option>
                            <option value="1">{localeStrings.profession_architect}</option>
                            <option value="12">{localeStrings.profession_private_customer}</option>
                            <option value="9">{localeStrings.profession_construction_industry}</option>
                            <option value="7">{localeStrings.profession_engineer}</option>
                            <option value="10">{localeStrings.profession_tile_worker}</option>
                            <option value="14">{localeStrings.profession_dealer}</option>
                        </ListInput>
                        <ListInput
                            className="contact-form-input"
                            id="phone"
                            floatingLabel={true}
                            label={localeStrings.phone}
                            type="tel"
                            placeholder={localeStrings.phone_placeholder}
                            errorMessage={localeStrings.format_wrong_text}
                            onInput={(value) => {
                                this._phone = value.target.value;
                            }}
                            validate
                            pattern="[0-9]*"
                            clearButton
                        />
                        {/* <ListInput
                            className="contact-form-input"
                            id="mobile_phone"
                            floatingLabel={true}
                            label={localeStrings.mobile_phone}
                            type="tel"
                            placeholder={localeStrings.mobile_phone_placeholder}
                            errorMessage={localeStrings.format_wrong_text}
                            onInput={(value) => {
                                this._mobilePhone = value.target.value;
                            }}
                            validate
                            pattern="[0-9]*"
                            clearButton
                        /> */}
                        <Block style={{ color: 'var(--f7-list-item-text-text-color)' }}>
                            {localeStrings.message_label_header}
                        </Block>
                        <TextEditor
                            onTextEditorChange={(value) => {
                                this._message = value;
                            }}
                            placeholder={localeStrings.message_placeholder}
                            mode="popover"
                            buttons={['bold', 'italic', 'underline', 'strikeThrough']}
                            value={this.props.precompiledNoteInfo}
                            style={{ '--f7-text-editor-height': '150px' }}
                        />
                    </List>

                    <List mediaList noHairlines={true}>

                        <Block style={{ color: 'var(--f7-list-item-text-text-color)', marginLeft: '0px' }}>
                            {localeStrings.info_validation_text}
                        </Block>

                        <ListItem
                            id="newsletter_checkbox"
                            onChange={(event) => {
                                this.setState({ newsletter_checked: event.currentTarget.checked })
                            }}
                            checked={this.state.newsletter_checked}
                            checkbox
                            title={localeStrings.newsletter}
                            text={localeStrings.newsletter_text} />
                        <ListItem
                            id="privacy_checkbox"
                            onChange={(event) => {
                                this.setState({ privacy_checked: event.currentTarget.checked })
                            }}
                            checked={this.state.privacy_checked}
                            checkbox
                            title={localeStrings.privacy}
                            text={localeStrings.privacy_text} >
                            <p style={{ paddingBottom: '25px' }} />
                        </ListItem>
                    </List>
                    <p onClick={() => { window.open(encodeURI(localeStrings.privacy_site), '_blank') }}><u>{localeStrings.privacy_link_text}</u></p>
                    <Block>
                        <Button
                            style={{ marginTop: '70px', width: 'fit-content', padding: 'inherit' }}
                            large
                            fill
                            onClick={() => { this.sendButtonClick(); }}>{localeStrings.submit_button_text}</Button>
                    </Block>
                </Block>
            </Page>
        )
    }

    sendButtonClick = () => {
        if (!this.state.privacy_checked) {
            this.showToastBottom(localeStrings.privacy_not_checked_error);
            return;
        }

        const validInputs = f7.input.validateInputs(document.getElementById("input_container"));
        if (!validInputs) {
            this.showToastBottom(localeStrings.not_valid_fields_error);
            return;
        }

        const jsonToSend = this.createJson(
            this._name,
            this._surname,
            this._email,
            this._city,
            this._zip,
            this._province,
            this._country,
            this._profession,
            this._phone,
            // this._mobilePhone,
            this._message,
            this.state.privacy_checked,
            this.state.newsletter_checked);

        //console.log(jsonToSend);
        this.sendContactFormToServer(jsonToSend);
    }

    createJson = (
        firstName,
        lastName,
        email,
        addressCity,
        addressZip,
        addressStateOrProvince,
        addressCountry,
        occupation,
        phone,
        // mobilePhone,
        notesInfo,
        consentPrivcy,
        consentNewsletter1) => {
        const payload =
        {
            'Brand': this.props.precompiledBrandName ? this.props.precompiledBrandName : 'Iris Ceramica Group',
            'LeadSource': 'Web - App Pikkart',
            'Subject': 'Contatto da SitoWeb FMG',
            'Firstname': firstName,
            'Lastname': lastName,
            'Email': email,
            'Address_Street': '',
            'Address_City': addressCity,
            'Address_Zip': addressZip,
            'Address_StateOrProvince': addressStateOrProvince,
            'Address_Country': addressCountry,
            'Occupation': occupation,
            'Company': '',
            'Phone': phone,
            'MobilePhone': phone,
            'Notes_Info': notesInfo,
            'Consent_Privacy': consentPrivcy,
            'Consent_Newsletter1': consentNewsletter1
        };

        return payload;
    }

    convertOccupation = (value) => {
        const intValue = parseInt(value)
        switch (intValue) {
            case 1:
                return localeStrings.profession_architect;
            case 7:
                return localeStrings.profession_engineer;
            case 9:
                return localeStrings.profession_construction_industry;
            case 10:
                return localeStrings.profession_tile_worker;
            case 12:
                return localeStrings.profession_private_customer;
            case 14:
                return localeStrings.profession_dealer;
            default:
                return "";
        }
    }

    sendContactFormToServer = (jsonForm) => {
        const self = this;
        axios.post('https://collectleadfromwebsitesfunctionapp.azurewebsites.net/api/FunctionCollectDataFromWebsites?code=SgyFXwPfJmjvj7H4sFvdwVQ8CUK9Bi25WrcOR4GC42HclQI1taCDnQ==&clientId=WebSites',
            jsonForm,
            {
                headers: {
                    'Content-Type': 'text/plain',
                }
            }).then((response) => {
                if (response.data.status === "OK") {
                    f7.dialog.alert(localeStrings.contact_from_success_text, localeStrings.contact_from_success_title, function () {
                        self.clearForm();
                    });
                }
                else {
                    f7.dialog.alert(localeStrings.contact_from_error_text, localeStrings.contact_from_error_title, function () {

                    });
                }
            }, (error) => {
                f7.dialog.alert(localeStrings.contact_from_error_text, localeStrings.contact_from_error_title, function () {

                });
            })
    }

    clearForm = () => {
        this.setState({ privacy_checked: false, newsletter_checked: false })
    }

    showToastBottom = (text) => {
        this.toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 3000,
        });
        this.toastBottom.open();
    };

    componentDidMount = () => {

    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);