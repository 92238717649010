import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, List, ListItem, AccordionContent, ListInput, Button, Popup, NavRight, Link, Preloader, f7, Icon, NavLeft, NavTitle } from 'framework7-react';
import Webcam from 'webcam-easy';
import PkCordova from '../../pikkart-cms/cordova';
import PkArDiscover from '../../pikkart-cms/ar/discover';
import AnalyticsRegister, { ANALYTICS_SCREEN } from '../../analytics-register';
import localeStrings from './discover-content-detail-local';

class DiscoverContentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pictureSource: null,
            destinationType: null,
            webcam: null,
            title: "",
            descr: "",
            imageBase64: "",
            imageWidth: 0,
            imageHeight: 0,
            saving: false,
            reRender: false
        }

        this.infoContent = {
            title: "",
            descr: "",
            imageBase64: "",
            date: "",
            webUrl: "",
            email: "",
            phone: "",
            fax: "",
            attchmBase64: "",
            attchmName: "",
            attchmDescr: "",
            youtubeUrl: "",
            youtubeName: "",
            audioBase64: "",
            googleUrl: "",
            pinUrl: "",
            twUrl: "",
            fbUrl: "",
            linkedinUrl: "",
            instUrl: "",
            skype: "",
            price: 0,
            ecommerceUrl: "",
            cameraPopupOpened: false,
        }

        this.onPhotoDataSuccess = this.onPhotoDataSuccess.bind(this);
    }

    /**
     * Funzione che restituisce il base64 dell'immagine selezionata o scattata
     * 
     * @param {string} imageData base64 dell'immagine
     */
    onPhotoDataSuccess(imageData) {
        let imagebase64 = "data:image/png;base64," + imageData;

        this.imageDimensions(imagebase64);

        this.infoContent.imageBase64 = imageData;
        this.setState({ imageBase64: imagebase64 });

        if (!this.props.newContent && this.infoContent.imageUrl) {
            delete this.infoContent.imageUrl;
        }
    }

    /**
     * Funzione che restituisce un errore in caso di non riuscita cattura dell'immagine selezionata o scattata
     * 
     * @param {string} message messaggio d'errore
     */
    onFail(message) {
        f7.toast.create({
            text: 'Failed because: ' + message,
            closeTimeout: 2000,
        }).open();
    }

    /**
     * Funzione che aspetta che l'immagine venga caricata
     */
    waitForElement = () => {
        if (document.getElementById("temp_image").src !== "") {
            let imageBase64 = document.getElementById("temp_image").src;
            this.infoContent.imageBase64 = imageBase64;
            this.setState({ imageBase64: imageBase64 });
            if (!this.props.newContent && this.infoContent.imageUrl) {
                delete this.infoContent.imageUrl;
            }
        } else {
            setTimeout(() => {
                this.waitForElement();
            }, 1500);
        }
    }

    /**
     * Funzione che crea una nuova view con immagine dalla camera
     */
    openCamera = () => {
        let picture = this.state.webcam.snap();

        this.imageDimensions(picture);

        this.infoContent.imageBase64 = picture;
        this.setState({ imageBase64: picture });

        if (!this.props.newContent && this.infoContent.imageUrl) {
            delete this.infoContent.imageUrl;
        }

        this.state.webcam.stop();
    }

    /**
     * Funzione che crea una nuova view con immagine dalla galleria
     * 
     * @param {object} e oggetto che contiene informazioni sull'immagine selezionata
     */
    openGallery = (e) => {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;
            let pictureSource = this.state.pictureSource;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL,
                sourceType: pictureSource.PHOTOLIBRARY,
                correctOrientation: true,
            });
            this.waitForElement();
        } else {
            let base64 = "";
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                base64 = reader.result;

                if (base64.substr(11, 4) === "jpeg") {
                    base64 = "data:image/png;base64," + base64.substr(23);
                }

                this.imageDimensions(base64);

                this.infoContent.imageBase64 = base64.split(",")[1];
                this.setState({ imageBase64: base64 });
            });

            if (!this.props.newContent && this.infoContent.imageUrl) {
                delete this.infoContent.imageUrl;
            }
        }
    }

    /**
     * Funzione che avvia la webcam del pc
     */
    startWebcam = () => {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL,
                cameraDirection: navigator.camera.Direction.BACK,
                correctOrientation: true,
            });

            this.waitForElement();
        }
        else {
            let webcamElement = document.getElementById('webcam');
            let canvas = document.getElementById('temp_canvas');
            webcamElement.width = document.getElementById('webcam-container').getBoundingClientRect().width;
            webcamElement.height = document.getElementById('webcam-container').getBoundingClientRect().width * (3 / 4);
            let webcam = new Webcam(webcamElement, 'user', canvas);
            this.setState({ webcam: webcam });
            webcam.start()
                .then((result) => {
                    console.log("webcam started");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    /**
     * Funzione che calcola la grandezza dell'immagine e la converte
     */
    imageDimensions = (base64) => {
        let image = new Image();

        image.onload = () => {
            let newImageWidth = document.getElementById("info-content-image").getBoundingClientRect().width;
            let newImageHeight = document.getElementById("info-content-image").getBoundingClientRect().width * image.height / image.width;
            this.setState({ imageWidth: newImageWidth, imageHeight: newImageHeight });
        }

        image.src = base64;
    }

    /**
     * Funzione che converte un file in una stringa base64
     * 
     * @param {object} file file da convertire
     * @param {string} param posizione dove salvare il base64
     */
    toBase64 = (file, param) => {
        document.getElementById("saveButton").disabled = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            switch (param) {
                case "attchmBase64":
                    this.infoContent.attchmBase64 = reader.result.split(",")[1];
                    break;
                case "audioBase64":
                    this.infoContent.audioBase64 = reader.result.split(",")[1];
                    break;

                default:
                    break;
            }

            if (this.infoContent.title && this.infoContent.descr) {
                document.getElementById("saveButton").disabled = false;
            }
        }
    }

    /**
     * Funzione che salva l'info content
     */
    saveContent = () => {
        this.setState({ saving: true });
        document.getElementById("preloader").style.display = 'block';
        document.getElementById("saveButton").style.display = 'none';
        document.getElementById("deleteButton").style.display = 'none';

        if (this.props.newContent) {
            PkArDiscover.addModelContent(this.props.modelData.model.contentId, this.infoContent)
                .then(res => {

                    console.log('saveContent result', res);

                    document.getElementById("preloader").style.display = 'none';
                    document.getElementById("saveButton").style.display = 'block';
                    document.getElementById("deleteButton").style.display = 'block';

                    if (res.result.code !== 200) {
                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    } else {
                        this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData, activeContentTab: true }, reloadAll: true });
                    }
                });
        } else {
            PkArDiscover.setModelContent(this.props.infoContent.id, this.infoContent)
                .then(res => {
                    document.getElementById("preloader").style.display = 'none';
                    document.getElementById("saveButton").style.display = 'block';
                    document.getElementById("deleteButton").style.display = 'block';

                    if (res.result.code !== 200) {
                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    } else {
                        this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData, activeContentTab: true }, reloadAll: true });
                    }
                });
        }
    }

    render() {
        return (
            <Page>
                <Navbar>
                    <NavLeft backLink="Back" onBackClick={() => {
                        this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData, activeContentTab: true }, reloadAll: true })
                    }} />
                    <NavTitle>{this.props.modelData.contentData.content.title}</NavTitle>
                </Navbar>
                <Block>
                    <List accordionList noHairlines inlineLabels style={{ fontSize: "1.1rem" }}>
                        <ListItem
                            accordionItem
                            accordionItemOpened
                            title={localeStrings.accordion_content_base_informations}>
                            <AccordionContent>
                                <ListInput
                                    label={localeStrings.accordion_content_title}
                                    placeholder={localeStrings.accordion_content_title}
                                    type="text"
                                    name="title"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.title : ""}
                                    maxlength="200"
                                    required
                                    validate
                                    onBlur={(e) => { this.infoContent.title = e.target.value; this.setState({ title: e.target.value }) }}
                                    clearButton />
                                <ListInput
                                    label={localeStrings.accordion_content_description}
                                    placeholder={localeStrings.accordion_content_description}
                                    type="text"
                                    name="descr"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.descr : ""}
                                    onBlur={(e) => { this.infoContent.descr = e.target.value; this.setState({ descr: e.target.value }) }}
                                    clearButton />
                                <ListInput
                                    input={false}
                                    label={localeStrings.accordion_content_image}
                                    required
                                    validate
                                    defaultValue={this.props.infoContent ? this.props.infoContent.imageBase64 : ""}>
                                    <div slot="input">
                                        <div
                                            id="info-content-image"
                                            className="info-content-image"
                                            style={{
                                                display: this.state.imageBase64 === "" ? "none" : "block",
                                                width: "50%",
                                            }}>
                                            {this.props.modifyInfoContent ?
                                                this.props.infoContent.imageUrl !== "" ?
                                                    <img src={this.props.infoContent.imageUrl} alt="Not found" style={{ maxWidth: "100%" }} />
                                                    :
                                                    <></>
                                                :
                                                <img src={this.state.imageBase64} alt="Not found"
                                                    style={{
                                                        width: this.state.imageWidth,
                                                        height: this.state.imageHeight
                                                    }} />
                                            }
                                        </div>
                                        <div className="image-button-container">
                                            {this.state.imageBase64 === "" ?
                                                <>
                                                    <Button
                                                        text={localeStrings.accordion_content_button_camera}
                                                        iconMaterial="photo_camera"
                                                        onClick={() => {
                                                            this.setState({ cameraPopupOpened: true });
                                                            this.startWebcam()
                                                        }} />
                                                    <input id="infoOpenGallery"
                                                        type={PkCordova.isCordova() ? "" : "file"}
                                                        onClick={() => {
                                                            if (PkCordova.isCordova()) {
                                                                this.openGallery()
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (!PkCordova.isCordova()) {
                                                                this.openGallery(e)
                                                            }
                                                        }} style={{ display: "none" }} />
                                                    <Button
                                                        text={localeStrings.accordion_content_button_gallery}
                                                        iconMaterial="collections"
                                                        onClick={() => { document.getElementById("infoOpenGallery").click() }} />
                                                </>
                                                :
                                                <Button
                                                    text={localeStrings.accordion_content_button_remove}
                                                    iconF7="trash_circle"
                                                    onClick={() => {
                                                        this.setState({ imageBase64: "", imageWidth: 0, imageHeight: 0 });
                                                        this.infoContent.imageBase64 = "";
                                                        if (!this.props.newContent) {
                                                            this.infoContent.imageUrl = null;
                                                        }
                                                    }}>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </ListInput>
                                <ListInput
                                    label={localeStrings.accordion_content_date_label}
                                    placeholder={localeStrings.accordion_content_date_placeholder}
                                    type="datepicker"
                                    name="date"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.date : ""}
                                    calendarParams={{ closeOnSelect: true, closeByOutsideClick: true }}
                                    onCalendarChange={(e) => { this.infoContent.date = new Date(e[0]).getTime() }}
                                    clearButton />
                                <ListInput
                                    label={localeStrings.accordion_content_date_url}
                                    placeholder={localeStrings.accordion_content_date_url}
                                    type="url"
                                    name="webUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.webUrl : ""}
                                    onBlur={(e) => { this.infoContent.webUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem
                            accordionItem
                            title={localeStrings.accordion_content_contacts}>
                            <AccordionContent>
                                <ListInput
                                    label={localeStrings.accordion_content_email}
                                    placeholder={localeStrings.accordion_content_email}
                                    type="email"
                                    name="email"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.email : ""}
                                    onBlur={(e) => { this.infoContent.email = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_phone}
                                    placeholder={localeStrings.accordion_content_phone}
                                    type="tel"
                                    name="phone"
                                    pattern="^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.phone : ""}
                                    onBlur={(e) => { this.infoContent.phone = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_fax}
                                    placeholder={localeStrings.accordion_content_fax}
                                    type="text"
                                    name="fax"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.fax : ""}
                                    onBlur={(e) => { this.infoContent.fax = e.target.value }}
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem
                            accordionItem
                            title={localeStrings.accordion_content_attachments}>
                            <AccordionContent>
                                {this.infoContent.attchmBase64 === "" ?
                                    <ListInput
                                        label={localeStrings.accordion_content_pdf}
                                        placeholder={localeStrings.accordion_content_pdf}
                                        type="file"
                                        accept=".pdf"
                                        name="attchmBase64"
                                        defaultValue={this.props.infoContent ? this.props.infoContent.attchmUrl : ""}
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                this.infoContent.attchmBase64 = this.toBase64(e.target.files[0], "attchmBase64");
                                                document.getElementsByName("attchmName")[0].value = e.target.files[0].name.split(".")[0];
                                                this.infoContent.attchmName = e.target.files[0].name.split(".")[0];

                                                if (!this.props.newContent && this.infoContent.attchmUrl) {
                                                    delete this.infoContent.attchmUrl;
                                                }
                                                this.setState({ reRender: !this.state.reRender });
                                            }
                                        }}
                                    />
                                    :
                                    <ListInput
                                        input={false}
                                        label={localeStrings.accordion_content_pdf}
                                        name="attchmBase64">
                                        <div slot="input" style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                            <Icon f7="cloud_download" style={{ color: "var(--f7-theme-color)", display: 'flex', alignSelf: 'center' }} />
                                            <Button
                                                text={localeStrings.accordion_content_button_remove}
                                                iconF7="trash_circle"
                                                onClick={() => {
                                                    this.infoContent.attchmBase64 = "";
                                                    document.getElementsByName("attchmName")[0].value = "";
                                                    this.infoContent.attchmName = "";

                                                    if (!this.props.newContent) {
                                                        this.infoContent.attchmUrl = null;
                                                    }
                                                    this.setState({ reRender: !this.state.reRender });
                                                }}
                                            />
                                        </div>
                                    </ListInput>
                                }
                                <ListInput
                                    label={localeStrings.accordion_content_file_name}
                                    placeholder={localeStrings.accordion_content_file_name}
                                    type="text"
                                    name="attchmName"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.attchmName : ""}
                                    onChange={(e) => { this.infoContent.attchmName = e.target.value }}
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_description}
                                    placeholder={localeStrings.accordion_content_description}
                                    type="text"
                                    name="attchmDescr"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.attchmDescr : ""}
                                    onBlur={(e) => { this.infoContent.attchmDescr = e.target.value }}
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem
                            accordionItem
                            title={localeStrings.accordion_content_media}>
                            <AccordionContent>
                                <ListInput
                                    label={localeStrings.accordion_content_youtube_url}
                                    placeholder={localeStrings.accordion_content_youtube_url}
                                    type="url"
                                    name="youtubeUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.youtubeUrl : ""}
                                    onBlur={(e) => { this.infoContent.youtubeUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_youtube_description}
                                    placeholder={localeStrings.accordion_content_youtube_placeholder_description}
                                    type="text"
                                    name="youtubeName"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.youtubeName : ""}
                                    onBlur={(e) => { this.infoContent.youtubeName = e.target.value }}
                                    clearButton
                                />
                                {this.infoContent.audioBase64 === "" ?
                                    <ListInput
                                        label={localeStrings.accordion_content_audio}
                                        placeholder={localeStrings.accordion_content_audio}
                                        type="file"
                                        accept=".mp3"
                                        name="audioBase64"
                                        defaultValue={this.props.infoContent ? this.props.infoContent.audioUrl : ""}
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                this.infoContent.audioBase64 = this.toBase64(e.target.files[0], "audioBase64");
                                                this.setState({ title: this.infoContent.title });

                                                if (!this.props.newContent) {
                                                    delete this.infoContent.audioUrl;
                                                }
                                            }
                                        }}
                                        clearButton
                                    />
                                    :
                                    <ListInput
                                        input={false}
                                        label={localeStrings.accordion_content_audio}
                                        name="audioBase64"
                                    >
                                        <div slot="input" style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                            <Icon f7="cloud_download" style={{ color: "var(--f7-theme-color)", display: 'flex', alignSelf: 'center' }} />
                                            <Button
                                                text={localeStrings.accordion_content_button_remove}
                                                iconF7="trash_circle"
                                                onClick={() => {
                                                    this.infoContent.audioBase64 = ""
                                                    this.setState({ title: this.infoContent.title });

                                                    if (!this.props.newContent && this.infoContent.audioUrl) {
                                                        this.infoContent.audioUrl = null;
                                                    }
                                                }}
                                            />
                                        </div>
                                    </ListInput>
                                }

                            </AccordionContent>
                        </ListItem>
                        <ListItem
                            accordionItem
                            title={localeStrings.accordion_content_social}>
                            <AccordionContent>
                                <ListInput
                                    label={localeStrings.accordion_content_share_url}
                                    placeholder={localeStrings.accordion_content_share_url}
                                    type="url"
                                    name="googleUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.googleUrl : ""}
                                    onBlur={(e) => { this.infoContent.googleUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_pinterest_url}
                                    placeholder={localeStrings.accordion_content_pinterest_placeholder_url}
                                    type="url"
                                    name="pinUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.pinUrl : ""}
                                    onBlur={(e) => { this.infoContent.pinUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_twitter_url}
                                    placeholder={localeStrings.accordion_content_twitter_placeholder_url}
                                    type="url"
                                    name="twUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.twUrl : ""}
                                    onBlur={(e) => { this.infoContent.twUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_facebook_url}
                                    placeholder={localeStrings.accordion_content_facebook_placeholder_url}
                                    type="url"
                                    name="fbUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.fbUrl : ""}
                                    onBlur={(e) => { this.infoContent.fbUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_linkedin_url}
                                    placeholder={localeStrings.accordion_content_linkedin_placeholder_url}
                                    type="url"
                                    name="linkedinUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.linkedinUrl : ""}
                                    onBlur={(e) => { this.infoContent.linkedinUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_instagram_url}
                                    placeholder={localeStrings.accordion_content_instagram_placeholder_url}
                                    type="url"
                                    name="instUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.instUrl : ""}
                                    onBlur={(e) => { this.infoContent.instUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_skype_url}
                                    placeholder={localeStrings.accordion_content_skype_placeholder_url}
                                    type="url"
                                    name="skype"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.skype : ""}
                                    onBlur={(e) => { this.infoContent.skype = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem
                            accordionItem
                            title={localeStrings.accordion_content_ecommerce}>
                            <AccordionContent>
                                <ListInput
                                    label={localeStrings.accordion_content_price}
                                    placeholder={localeStrings.accordion_content_price}
                                    type="number"
                                    name="price"
                                    step="any"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.price === 0 ? "" : this.props.infoContent.price : ""}
                                    onBlur={(e) => { this.infoContent.price = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label={localeStrings.accordion_content_ecommerce_url}
                                    placeholder={localeStrings.accordion_content_ecommerce_placeholder_url}
                                    type="url"
                                    name="e-commerceUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.ecommerceUrl : ""}
                                    onBlur={(e) => { this.infoContent.ecommerceUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                    </List>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 2%' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Preloader id="preloader" color="multi" size="30" style={{ display: 'none', paddingRight: '28px' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                text={localeStrings.accordion_content_button_delete}
                                id="deleteButton"
                                className="open-confirm"
                                style={{ fontSize: "1rem", width: "100px", height: "40px" }}
                                iconF7="trash"
                                onClick={() => {
                                    f7.dialog.confirm(
                                        'Do you want to delete this content?',
                                        'Delete',
                                        () => {
                                            if (!this.props.newContent) {
                                                PkArDiscover.deleteModelContent(this.props.infoContent.id);
                                            }
                                            this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData, activeContentTab: true }, reloadAll: true });
                                        });
                                }} />
                            <Button
                                text={localeStrings.accordion_content_button_save}
                                id="saveButton"
                                disabled={this.state.title !== "" ? false : true}
                                style={{ fontSize: "1rem", width: "100px", height: "40px" }}
                                iconF7="checkmark_alt_circle"
                                onClick={() => { this.saveContent() }} />
                        </div>
                    </div>
                    <img id="temp_image" alt='view' style={{ display: "none" }} />
                    <canvas id="temp_canvas" style={{ display: "none" }} />
                </Block>
                <Popup className="info-camera-popup"
                    opened={this.state.cameraPopupOpened && !PkCordova.isCordova()}>
                    <Page>
                        <Navbar title="Camera">
                            <NavRight>
                                <Link popupClose onClick={() => { this.state.webcam.stop() }} iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavRight>
                        </Navbar>

                        <Block>
                            <div id="webcam-container">
                                <video id="webcam" autoPlay playsInline></video>
                            </div>
                            <Button className="popup-close" onClick={() => {
                                this.setState({ cameraPopupOpened: false })
                                this.openCamera()
                            }} large fill> {localeStrings.take_picture}</Button >
                        </Block>
                    </Page>
                </Popup>
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.DISCOVER_VIEW_DETAIL);
        if (this.props.infoContent) {
            this.infoContent = this.props.infoContent;
            this.infoContent.attchmBase64 = this.props.infoContent.attchmUrl;
            this.infoContent.audioBase64 = this.props.infoContent.audioUrl;
            this.setState({
                title: this.props.infoContent.title,
                descr: this.props.infoContent.descr
            })
        }

        this.setState({ imageBase64: this.infoContent.imageBase64 });

        if (PkCordova.isCordova()) {
            this.setState({
                pictureSource: navigator.camera.PictureSourceType,
                destinationType: navigator.camera.DestinationType
            });
        }
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverContentDetail);