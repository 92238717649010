import { Pk } from "../index";
import { PkLog } from "../log";
import { PkCommons } from "../commons";

export class PkArOpenCv {
  static load = () => {
    return new Promise((resolve, reject) => {
      PkCommons.injectJsScript("opencv-injected-js", Pk.getConfig().openCvUrl)
        .then(() => {
          PkLog.log("success to load " + Pk.getConfig().openCvUrl);
          // eslint-disable-next-line no-undef
          if (window.cv.getBuildInformation) {
            PkLog.log(window.cv.getBuildInformation());
            resolve();
          } else {
            // WASM
            window.cv["onRuntimeInitialized"] = () => {
              PkLog.log(window.cv.getBuildInformation());
              resolve();
            };
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          PkLog.error("failed to load " + Pk.getConfig().openCvUrl);
          reject(error);
        });
    });
  };
}
export default PkArOpenCv;
