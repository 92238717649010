import { Pk } from "../../index";
import { PkApiNet } from "../../api/net";

export class PkApiCallsInvitations {
  static declineInvitation = (callUniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          callUniqueId +
          "/invitations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default PkApiCallsInvitations;
