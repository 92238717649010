import React from 'react';
import { Link, CardHeader } from 'framework7-react';

const ContentHeader = (props) => {
    const buttonColor = process.env.REACT_APP_CUSTOM_CLOSE_BUTTON_COLOR ? process.env.REACT_APP_CUSTOM_CLOSE_BUTTON_COLOR : "";
    let closeButton = null;
    if (props.showCloseButton) {
        closeButton =
            <Link
                cardClose
                className="card-opened-fade-in"
                style={{ position: 'absolute', right: '15px', top: '15px' }}
                iconF7="xmark_circle_fill"
                color={buttonColor} />


        if (props.closeButtonFn) {
            closeButton =
                <Link
                    onClick={() => props.closeButtonFn()}
                    className="card-opened-fade-in"
                    style={{ position: 'absolute', right: '15px', top: '15px' }}
                    iconF7="xmark_circle_fill"
                    color={buttonColor} />
        }
    }

    return (
        <div style={{
            height: 'auto',
        }}>
            <CardHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    position: (process.env.REACT_APP_SHOW_CONTENT_IMAGE_UNDER_TITLE && props.isDetailVisible) ? 'relative' : 'absolute',
                    fontSize: 'var(--f7-card-expandable-header-font-size)',
                    fontFamily: 'var(--f7-font-family)',
                    fontWeight: 'var(--f7-card-expandable-header-font-weight)'
                }}>

                <section id={props.id} className='hero-header-text'>
                    {props.content.title}
                </section>
            </CardHeader>
            {closeButton}
        </div>)
}

export default ContentHeader;