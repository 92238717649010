import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar } from 'framework7-react';
import ContentListItem from '../../contents/content-list-item';
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../../analytics-register';
import { CONTENT_VIEW } from '../../pikkart-cms/contents';
import MobileDetect from 'mobile-detect';

class AssetRecognitionResult extends React.Component {

    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            errorMessage: null,
            infoContentsData: [],
            filteredInfoContentsData: [],
            allowInfinite: true,
            showPreloader: true,
            enableSearchBar: false,
        };
    }

    render() {

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={this.props.title}
                        logo='./img/logo/logo.png'
                        showNavRight={true}
                        showArrowAsNavRight={true}
                        searchBarEnabled={JSON.parse(process.env.REACT_APP_ENABLE_SEARCH_BAR) && this.state.enableSearchBar}
                        onSearch={this.onSearchBarSearchHandler} 
                        onBack={this.props.returnFromResults}/>
                </Navbar>

                <div>
                    {this.getInfoContentsData()}
                </div>

            </Page>
        )
    }


    getInfoContentsData = () => {

        if (this.props.contents &&
            this.props.contents.length > 0) {
            const ret = this.props.contents.map((item, index) => {
                let brandLine = "";
                let skinColor = '#000000';
                let colorFont = '#FFFFFF';
                let brandName = '';

                if (item.brand) {
                    brandName = item.brand.name;
                }
                if (item.skin) {
                    skinColor = item.skin.hdBgColor
                    colorFont = item.skin.hdFontColor
                }

                if (item.brand || item.skin) {
                    brandLine = (<div className="categoria" style={{ borderBottomColor: skinColor }}>
                        <span style={{ color: colorFont, backgroundColor: skinColor }}>{brandName}</span>
                    </div>)
                }
                const md = new MobileDetect(window.navigator.userAgent);
                if (md.phone()) {
                    return (
                        <div key={'info-content-' + index}>
                            {brandLine}
                            <ContentListItem
                                contentData={item}
                                key={'info-content-' + index} onCardClickHandler={(item) => {
                                    this.onCardClick(item);
                                }}
                                onInfoClickHandler={(params) => {
                                    this.onInfoClick(params);
                                }} />
                        </div>
                    )
                }
                else {
                    let cardWidth = null;
                    if (window.outerWidth < window.outerHeight) { //portrait
                        if (window.outerWidth < 770) {
                            cardWidth = window.outerWidth / 100 * 46.13
                        }
                        if (window.outerWidth < 835) {
                            cardWidth = window.outerWidth / 100 * 46.39
                        }
                        else if (window.outerWidth < 1025) {
                            cardWidth = window.outerWidth / 100 * 22.57
                        }
                    }
                    else { //landscape
                        if (window.outerWidth < 1025) {
                            cardWidth = window.outerWidth / 100 * 22.57
                        }
                        if (window.outerWidth < 1115) {
                            cardWidth = window.outerWidth / 100 * 22.57
                        }
                        else if (window.outerWidth < 1370) {
                            cardWidth = window.outerWidth / 100 * 22.75
                        }
                        else if (window.outerWidth < 1700) {
                            cardWidth = window.outerWidth / 100 * 23.86
                        }
                    }

                    return (
                        <div key={'info-content-' + index}>
                            {brandLine}
                            <ContentListItem
                                cardWidth={cardWidth}
                                contentData={item}
                                key={'info-content-' + index} onCardClickHandler={(item) => {
                                    this.onCardClick(item);
                                }}
                                onInfoClickHandler={(params) => {
                                    this.onInfoClick(params);
                                }} />
                        </div>
                    )
                }
            });

            if (this.state.infoContentsData && this.state.infoContentsData.length > 0 && this.state.infoContentsData[0].content) {
                this.checkEnableSearchBar(this.state.infoContentsData[0].content.ctnView);
            }


            return (
                <Block style={{ padding: '0' }}>
                    <div className="demo-expandable-cards">{ret}</div>
                    {/*<PageContent style={{ padding: '0' }}>{ret}</PageContent>*/}
                </Block>)

        }
        else {
            if (this.state.errorMessage) {
                return (

                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }
    }

    checkEnableSearchBar = (content) => {
        let enableSearchBar = false;
        switch (content) {
            case CONTENT_VIEW.DISCOVER_MODEL:
                enableSearchBar = true;
                break;
            case CONTENT_VIEW.INFOCONTENT:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.MARKERLESS:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.DISCOVER_KEYPOINT:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.PLACE:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.ITINERARY:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.AR_SCENE:
                enableSearchBar = true;
                break;
            case -12: //lastre asset recognition
                enableSearchBar = false;
                break;
            default:
                break;
        }

        if (this.state.enableSearchBar !== enableSearchBar) {
            this.setState({ enableSearchBar: enableSearchBar })
        }
    }

    onCardClick = (item) => {
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, item)
    }

    onInfoClick = (params) => {
        if (this.props.f7router.currentRoute.url !== "/contact") {
            this.props.f7router.navigate('/contact', {
                props: {
                    showNavRight: true,
                    showArrowAsNavRight: true,
                    precompiledBrandName: params.brandName,
                    precompiledNoteInfo: params.contentTitle
                }
            });
        }
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }

    onSearchBarSearchHandler = (searchbar, query, previousQuery) => {
        if (query) {

            let updatedContentsData = [];

            let infoContentsData = this.state.infoContentsData;
            infoContentsData.forEach(item => {
                if (item.content.title.toLowerCase().includes(query.toLowerCase())) {
                    updatedContentsData.push(item);
                }
            });

            this.setState({ filteredInfoContentsData: updatedContentsData })
        }
        else {
            this.setState({ filteredInfoContentsData: this.state.infoContentsData })
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        favoriteContents: state.app.favoriteContents
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AssetRecognitionResult);