import { isAndroid, isIOS, isTablet } from "react-device-detect";

import { PkCordova } from "../cordova/index";
import { PkApiCalls } from "../api/calls/calls";
import { PkLog } from "../log";
import { PkStore } from "../redux/store";
import { PkReduxActions } from "../redux/actions";
import { PkStorage } from "../storage";
import { Pk } from "../index";
import { PkApiCallsCommunicationProvider } from "../api/calls/communicationProvider";
import { PkCommons } from "../commons";
import { PkArVideoSegmentation } from "../ar/segmentation";
import { PkLocalization } from "../localization";

let playingAudio;

export class PkMedia {
  static _devicesCheckIntervalId = null;

  static isInputDeviceCheckRunning = () => {
    return PkMedia._devicesCheckIntervalId != null;
  };

  static isInputDevicesDetectionCompleated = () => {
    return PkStore.getState().inputDevicesDetectionCompleated;
  };

  static hasBrowserMediaPermission = (detectedDevices) => {
    //navigator.mediaDevices.enumerateDevices() will return an empty label attribute value if the permission for accessing the mediadevice is not given
    //https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
    return (
      detectedDevices &&
      detectedDevices.length > 0 &&
      detectedDevices[0].deviceId &&
      detectedDevices[0].label
    );
  };

  static _askingBrowserMediaPermission = false;
  static askBrowserMediaPermission = () => {
    return new Promise((resolve, reject) => {
      if (!PkMedia._askingBrowserMediaPermission) {
        PkMedia._askingBrowserMediaPermission = true;
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((stream) => {
            const tracks = stream.getTracks();
            for (let i = 0; i < tracks.length; i++) {
              tracks[i].stop();
            }
          })
          .catch((errorMsg) => {
            PkLog.error(errorMsg);
          })
          .finally(() => {
            PkMedia._askingBrowserMediaPermission = false;
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  static startDevicesCheck = () => {
    PkMedia._devicesCheckIntervalId = setInterval(function () {
      //   navigator.mediaDevices.addEventListener('devicechange', function(event) {

      if (!PkApiCalls.getActiveCall() || !PkMedia._devices) {
        PkMedia.getCurrentDevices()
          .then(function (devices) {
            if (
              /* !PkCordova.isCordova() &&*/
              !PkMedia.hasBrowserMediaPermission(devices.input.audio) //testo solo l'audio, perchè su iOS e Android i device video sono chiodati dall'applicazione
            ) {
              //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
              PkMedia.askBrowserMediaPermission();
              return;
            }

            PkMedia.syncReduxDevices(devices);
          })
          .catch(function (error) {
            PkLog.error("getCurrentDevices", error);
          });
      }
    }, 5000);
  };

  static stopDevicesCheck() {
    clearInterval(PkMedia._devicesCheckIntervalId);
    PkMedia._devicesCheckIntervalId = null;
  }

  static syncReduxDevices = (devices) => {
    let reduxVideoDevices = PkStore.getState().videoInputDevices;
    if (!PkMedia.equalInputDevices(reduxVideoDevices, devices.input.video)) {
      PkStore.get().dispatch(
        PkReduxActions.setVideoInputDevices(devices.input.video)
      );
      if (
        !PkMedia.devicesListContains(
          devices.input.video,
          PkMedia.getSelectedVideoInputDevice()
        )
      )
        PkMedia.selectVideoInputDevice(null);
    }

    let reduxAudioDevices = PkStore.getState().audioInputDevices;
    if (!PkMedia.equalInputDevices(reduxAudioDevices, devices.input.audio)) {
      PkStore.get().dispatch(
        PkReduxActions.setAudioInputDevices(devices.input.audio)
      );
      if (
        !PkMedia.devicesListContains(
          devices.input.audio,
          PkMedia.getSelectedAudioInputDevice()
        )
      )
        PkMedia.selectAudioInputDevice(null);
    }

    let reduxAudioOutputDevices = PkStore.getState().audioOutputDevices;
    if (
      !PkMedia.equalInputDevices(reduxAudioOutputDevices, devices.output.audio)
    ) {
      PkStore.get().dispatch(
        PkReduxActions.setAudioOutputDevices(devices.output.audio)
      );
      if (
        !PkMedia.devicesListContains(
          devices.output.audio,
          PkMedia.getSelectedAudioOutputDevice()
        )
      )
        PkMedia.selectAudioOutputDevice(null);
    }
  };

  static selectDefaultDevices = () => {
    return new Promise((resolve, reject) => {
      PkMedia.getDevices()
        .then(function (devices) {
          let defaultDevices = {
            input: { video: null, audio: null },
            output: { audio: null },
          };

          if (devices) {
            if (devices.input) {
              //l'ultimo della lista nei dispositivi mobile è di solito la fotocamera posteriore
              let video = devices.input.video
                ? devices.input.video[devices.input.video.length - 1]
                : null;
              let audio = devices.input.audio ? devices.input.audio[0] : null;
              PkMedia.selectVideoInputDevice(video);
              PkMedia.selectAudioInputDevice(audio);

              defaultDevices.input.video = video;
              defaultDevices.input.audio = audio;
            }
            if (devices.output) {
              let audio = devices.output.audio ? devices.output.audio[0] : null;
              PkMedia.selectAudioOutputDevice(audio);

              defaultDevices.output.audio = audio;
            }
          }

          resolve(defaultDevices);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static _devices = null;

  static _setDevices = (devices) => {
    PkMedia._devices = devices;

    if (!PkMedia.isInputDevicesDetectionCompleated()) {
      PkStore.get().dispatch(
        PkReduxActions.setInputDevicesDetectionCompleated()
      );
    }

    PkMedia.syncReduxDevices(devices);
  };

  static getDevices = () => {
    return new Promise((resolve, reject) => {
      if (!PkMedia._devices) {
        PkMedia.getCurrentDevices()
          .then((devices) => {
            resolve(PkMedia._devices);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(PkMedia._devices);
      }
    });
  };

  static getCurrentDevices = () => {
    return new Promise((resolve, reject) => {
      try {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            let video = [];
            let audio = [];
            let audioOutput = [];

            devices.forEach(function (device) {
              let newDevice = {
                label: device.label,
                deviceId: device.deviceId,
                constraint: { deviceId: { exact: device.deviceId } },
              };
              if (
                device.kind === "videoinput" &&
                device.deviceId !== "default"
              ) {
                if (isTablet) {
                  newDevice.constraint.width = {
                    min: 640,
                    ideal: 1280,
                    max: 1920,
                  };
                  newDevice.constraint.height = {
                    min: 480,
                    ideal: 720,
                    max: 1080,
                  };
                }
                video.push(newDevice);
              } else if (device.kind === "audioinput") {
                audio.push(newDevice);
              } else if (device.kind === "audiooutput") {
                audioOutput.push(newDevice);
              }
            });

            if (isAndroid || isIOS) {
              audio = [];
              video = [];
              let video1 = {
                label: PkLocalization.media_backcamera,
                deviceId: "environment",
                constraint: {
                  facingMode: { exact: "environment" },
                },
              };
              if (isTablet) {
                video1.constraint.width = { min: 640, ideal: 1280, max: 1920 };
                video1.constraint.height = { min: 480, ideal: 720, max: 1080 };
              }
              video.push(video1);

              let video2 = {
                label: PkLocalization.media_frontcamera,
                deviceId: "user",
                constraint: {
                  facingMode: { exact: "user" },
                },
              };
              if (isTablet) {
                video2.constraint.width = { min: 640, ideal: 1280, max: 1920 };
                video2.constraint.height = { min: 480, ideal: 720, max: 1080 };
              }
              video.push(video2);

              let audio1 = {
                label: "default",
                deviceId: "default",
                constraint: true,
              };
              audio.push(audio1);
            }

            PkMedia._setDevices({
              input: { video: video, audio: audio },
              output: { audio: audioOutput },
            });
            resolve(PkMedia._devices);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(new Error(error));
      }
    });
  };

  static getAudioVideoTracks = () => {
    return new Promise((resolve, reject) => {
      let tracks = [];
      //PkMedia.getVideoInputDeviceTrack()
      PkApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
        PkMedia.getSelectedVideoInputDevice()
      )
        .then((track) => {
          if (track) {
            tracks.push(track);
          }
        })
        .catch((error) => {
          PkLog.error(error);
        })
        .finally(() => {
          PkMedia.getAudioInputDeviceTrack()
            .then((track) => {
              if (track) {
                tracks.push(track);
              }
            })
            .catch((error) => {
              PkLog.error(error);
            })
            .finally(() => {
              resolve(tracks);
            });
        });
    });
  };

  static stopTrack = (track) => {
    if (track) {
      if (track.track) {
        track.track.stop();
        if (track.track.mediaStreamTrack) {
          track.track.mediaStreamTrack.stop();
        }
      } else {
        track.stop();
        if (track.mediaStreamTrack) {
          track.mediaStreamTrack.stop();
        }
      }
    }
  };

  static getDefaultVideoTrackDimensions = () => {
    return { width: 480, height: 640 };
  }

  /***************************
   * VIDEO INPUT
   **************************/
  static _gettingVideoInputDeviceTrack = false;
  static _videoInputDeviceTrack = null;
  static getVideoInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      const error = (errorMsg) => {
        PkLog.error("getVideoInputDeviceTrack ERROR", errorMsg);
        PkMedia._videoInputDeviceTrack = null;
        PkMedia._gettingVideoInputDeviceTrack = false;
        reject(errorMsg);
      };

      if (!PkMedia._gettingVideoInputDeviceTrack) {
        PkMedia._gettingVideoInputDeviceTrack = true;

        PkMedia.stopTrack(PkMedia._videoInputDeviceTrack);

        setTimeout(() => {
          let device = PkMedia.getSelectedVideoInputDevice();
          if (!device) {
            PkMedia._videoInputDeviceTrack = null;
            PkMedia._gettingVideoInputDeviceTrack = false;
            resolve(PkMedia._videoInputDeviceTrack);
          } else {
            if (
              PkMedia.isDeviceTrackEnabled(
                device,
                PkMedia._videoInputDeviceTrack
              )
            ) {
              PkMedia._gettingVideoInputDeviceTrack = false;
              return PkMedia._videoInputDeviceTrack;
            } else {
              PkApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
                device,
                true
              )
                .then((track) => {
                  PkCommons.waitUntil(() => {
                    return (
                      track &&
                      track.dimensions &&
                      track.dimensions.width &&
                      track.dimensions.height
                    );
                  })
                    .then(() => {
                      PkMedia._videoInputDeviceTrack = track;
                      PkMedia._gettingVideoInputDeviceTrack = false;
                      resolve(PkMedia._videoInputDeviceTrack);
                    })
                    .catch((errorMsg) => {
                      error(errorMsg);
                    });
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        }, 200);
      } else {
        PkCommons.waitUntil(() => {
          return !PkMedia._gettingVideoInputDeviceTrack;
        }).then(() => {
          resolve(PkMedia._videoInputDeviceTrack);
        });
      }
    });
  };

  static stopVideoInputDeviceTrack = () => {
    if (
      PkMedia._videoInputDeviceTrack &&
      !PkMedia._videoInputDeviceTrack.isStopped
    ) {
      PkMedia._videoInputDeviceTrack.stop();
    }
  };

  static selectVideoInputDeviceId = (deviceId) => {
    let device = PkMedia.getDeviceFromList(
      PkStore.getState().videoInputDevices,
      deviceId
    );
    PkMedia.selectVideoInputDevice(device);
  };

  static selectVideoInputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;
    if (!device) {
      device = null;
    }

    let selectedDevice = PkStore.getState().selectedVideoInputDevice;
    if (!PkMedia.equalDevice(device, selectedDevice)) {
      PkStorage.setMediaInputVideo(deviceId);
      PkStore.get().dispatch(
        PkReduxActions.setSelectedVideoInputDevice(device)
      );

      //se c'è una chiamata in corso pubblico la nuova traccia
      if (device && PkApiCalls.getActiveCall()) {
        PkApiCalls._onSelectedVideoInputDeviceChanged(device);
      }
    }
  };

  static getSelectedVideoInputDevice = () => {
    let videoInput = PkStore.getState().selectedVideoInputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !videoInput &&
      PkMedia._devices &&
      PkMedia._devices.input &&
      PkMedia._devices.input.video
    ) {
      let storageDeviceId = PkStorage.getMediaInputVideo();
      if (storageDeviceId) {
        videoInput = PkMedia.getDeviceFromList(
          PkMedia._devices.input.video,
          storageDeviceId
        );
        PkMedia.selectVideoInputDevice(videoInput);
      }
    }

    return videoInput;
  };

  /***************************
   * AR VIDEO INPUT
   **************************/

  static isArEnabled = () => {
    return Pk.getConfig().arEnabled && !isAndroid && !isIOS;
  };

  static isArVideoInputDeviceEnable = () => {
    let enabled = PkMedia.isArEnabled();
    if (enabled) {
      enabled = PkStorage.isArVideoInputDeviceEnable();
      if (enabled !== PkStore.getState().isArVideoInputDeviceEnable) {
        PkStore.get().dispatch(
          PkReduxActions.setArVideoInputDeviceEnable(enabled)
        );
      }
    }
    return enabled;
  };

  static setArVideoInputDeviceEnable = (enable) => {
    PkStorage.setArVideoInputDeviceEnable(enable);
    if (enable !== PkStore.getState().isArVideoInputDeviceEnable) {
      PkStore.get().dispatch(
        PkReduxActions.setArVideoInputDeviceEnable(enable)
      );
    }
  };

  static selectArVideoInputDeviceId = (deviceId) => {
    return new Promise((resolve, reject) => {
      let device = PkMedia.getDeviceFromList(
        PkStore.getState().arVideoInputDevices,
        deviceId
      );
      PkMedia.selectArVideoInputDevice(device);
    });
  };

  static _createArCanvas = (track) => {
    let canvas = document.createElement("CANVAS");
    canvas.width = track.dimensions.width;
    canvas.height = track.dimensions.height;
    return canvas;
  };

  static createTrackFromCanvas = (canvas) => {
    let mediaStream = canvas.captureStream();
    let tracks = mediaStream.getTracks();
    if (tracks && tracks.length > 0) {
      return PkApiCallsCommunicationProvider.getLocalVideoTrack(tracks[0]);
    }
    return null;
  };

  static _gettingArVideoInputDeviceTrack = false;
  static _gettingArVideoProcessedTrack = false;
  static _arVideoInputDeviceTrack = null;
  static _arVideoProcessedTrack = null;
  static _arVideoCanvas = null;
  static _arVideoSegmentation = null;
  static _arVideo = null;

  static getArVideoCanvas = () => {
    return PkMedia._arVideoCanvas;
  };

  static _resetArVideoSegmentation = () => {
    if (PkMedia._arVideoSegmentation) {
      if (PkMedia._arVideoSegmentation.isProcessing()) {
        PkMedia._arVideoSegmentation.stopProcessing();
      }
      PkMedia._arVideoSegmentation = null;
    }
  };

  static resetArVideoInputDeviceTrack = () => {
    if (PkMedia.isArEnabled()) {
      PkMedia._resetArVideoSegmentation();

      if (PkMedia._arVideo) {
        PkMedia._arVideo.remove();
        PkMedia._arVideo = null;
      }

      PkMedia.stopTrack(PkMedia._arVideoProcessedTrack);
      PkMedia.stopTrack(PkMedia._arVideoInputDeviceTrack);

      PkMedia._arVideoInputDeviceTrack = null;
      PkMedia._arVideoProcessedTrack = null;
      PkMedia._arVideoCanvas = null;
    }
  };

  static getArVideoNode = () => {
    return PkMedia._arVideo;
  };

  static getArVideoSegmentation = () => {
    return PkMedia._arVideoSegmentation;
  };

  static getArVideoInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve(PkMedia._arVideoInputDeviceTrack);
      };
      const error = (errorMsg) => {
        PkLog.error("getVideoInputDeviceTrack ERROR", errorMsg);
        PkMedia.resetArVideoInputDeviceTrack();
        PkMedia._gettingArVideoInputDeviceTrack = false;
        reject(errorMsg);
      };

      if (PkMedia.isArEnabled()) {
        if (!PkMedia._gettingArVideoInputDeviceTrack) {
          PkMedia._gettingArVideoInputDeviceTrack = true;

          let device = PkMedia.getSelectedArVideoInputDevice();
          if (!device) {
            PkMedia.resetArVideoInputDeviceTrack();

            PkMedia._gettingArVideoInputDeviceTrack = false;
            success();
          } else {
            if (
              PkMedia.isDeviceTrackEnabled(
                device,
                PkMedia._arVideoInputDeviceTrack
              )
            ) {
              PkMedia._gettingArVideoInputDeviceTrack = false;
              success();
            } else {
              //  PkMedia.stopArVideoInputDeviceTrack();
              PkMedia.stopTrack(PkMedia._arVideoProcessedTrack);
              PkMedia.stopTrack(PkMedia._arVideoInputDeviceTrack);
              PkApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
                device,
                true
              )
                .then((track) => {
                  PkCommons.waitUntil(() => {
                    return (
                      track &&
                      track.dimensions &&
                      track.dimensions.width &&
                      track.dimensions.height
                    );
                  })
                    .then(() => {
                      PkMedia._arVideoInputDeviceTrack = track;
                      PkMedia._arVideoInputDeviceTrack.deviceId =
                        device.deviceId;
                      PkMedia._gettingArVideoInputDeviceTrack = false;
                      success();
                    })
                    .catch((errorMsg) => {
                      error(errorMsg);
                    });
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        } else {
          PkCommons.waitUntil(() => {
            return !PkMedia._gettingArVideoInputDeviceTrack;
          }).then(() => {
            success();
          });
        }
      } else {
        reject("ar is not enabled");
      }
    });
  };

  static logVideoTracks = () => {
    const getTrackState = (track) => {
      if (track) {
        return (
          "isStarted=" +
          track.isStarted +
          " isStopped=" +
          track.isStopped +
          " isEnabled=" +
          track.isEnabled
        );
      } else {
        return null;
      }
    };

    PkLog.log(
      "_videoInputDeviceTrack:" + getTrackState(PkMedia._videoInputDeviceTrack),
      PkMedia._videoInputDeviceTrack
    );
    PkLog.log(
      "_arVideoInputDeviceTrack" +
      getTrackState(PkMedia._arVideoInputDeviceTrack),
      PkMedia._arVideoInputDeviceTrack
    );
    PkLog.log(
      "_arVideoProcessedTrack" + getTrackState(PkMedia._arVideoProcessedTrack),
      PkMedia._arVideoProcessedTrack
    );
    PkLog.log("_arVideo", PkMedia._arVideo);
    PkLog.log("_arVideoCanvas", PkMedia._arVideoCanvas);
    PkLog.log("_arVideoSegmentation", PkMedia._arVideoSegmentation);
  };

  static getHtmlVideoFromTrack = (track) => {

    return new Promise((resolve, reject) => {

      if (!PkCordova.isCordova() || !isIOS || track.kind !== "video") {
        resolve(track.attach());
      }
      else {
        // Create a video element in memory (not yet in the DOM).
        var videoElement = document.createElement('video');
        //  videoElement.style.zIndex = "-1";

        // Tell the plugin to monitor it.
        window.cordova.plugins.iosrtc.observeVideo(videoElement);

        // Attach the MediaStream to it.
        track.attach(videoElement);

        // When the stream is ready to be rendered then append the video
        // element to the DOM.
        videoElement.addEventListener('canplay', function () {
          resolve(videoElement);
        });
      }

    });
  }

  static getArVideoProcessedTrack = (trainingOnly) => {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve(PkMedia._arVideoProcessedTrack);
      };
      const error = (errorMsg) => {
        PkLog.error("getArVideoProcessedTrack ERROR", errorMsg);
        PkMedia.resetArVideoInputDeviceTrack();
        PkMedia._gettingArVideoProcessedTrack = false;
        reject(errorMsg);
      };

      if (PkMedia.isArEnabled()) {
        if (!PkMedia._gettingArVideoProcessedTrack) {
          PkMedia._gettingArVideoProcessedTrack = true;

          let device = PkMedia.getSelectedArVideoInputDevice();
          if (!device) {
            PkMedia.resetArVideoInputDeviceTrack();

            PkMedia._gettingArVideoProcessedTrack = false;
            success();
          } else {
            if (
              PkMedia._arVideo &&
              PkMedia._arVideoCanvas &&
              PkMedia._arVideoSegmentation &&
              PkMedia.isDeviceTrackEnabled(
                device,
                PkMedia._arVideoProcessedTrack
              )
            ) {
              if (!PkMedia._arVideoSegmentation.isProcessing()) {
                PkMedia._arVideoSegmentation.startProcessing();
              }

              PkMedia._gettingArVideoProcessedTrack = false;
              success();
            } else {
              PkMedia.getArVideoInputDeviceTrack()
                .then((track) => {
                  try {

                    PkMedia.getHtmlVideoFromTrack(track)
                      .then(htmlVideoEl => {

                        PkMedia._arVideo = htmlVideoEl;
                        PkMedia._arVideo.width = track.dimensions.width;
                        PkMedia._arVideo.height = track.dimensions.height;

                        PkMedia._resetArVideoSegmentation();

                        PkMedia._arVideoCanvas = PkMedia._createArCanvas(track);

                        PkMedia._arVideoSegmentation =
                          PkArVideoSegmentation.initVideo(PkMedia._arVideo, {
                            targetCanvas: PkMedia._arVideoCanvas,
                            trainingOnly: trainingOnly,
                          });
                        PkMedia._arVideoSegmentation.onProcessError = (
                          errorMsg
                        ) => {
                          PkMedia._arVideoSegmentation.stopProcessing();
                          error(errorMsg);
                        };
                        PkMedia._arVideoSegmentation.startProcessing();

                        PkMedia._arVideoProcessedTrack =
                          PkMedia.createTrackFromCanvas(PkMedia._arVideoCanvas);
                        PkMedia._arVideoProcessedTrack.deviceId = device.deviceId;

                        PkCommons.waitUntil(
                          () => {
                            return (
                              PkMedia._arVideoProcessedTrack &&
                              PkMedia._arVideoProcessedTrack.isStarted === true
                            );
                          },
                          50,
                          30
                        )
                          .then(() => {
                            PkMedia._gettingArVideoProcessedTrack = false;
                            success();
                          })
                          .catch((errorMsg) => {
                            error(errorMsg);
                          });
                      })
                      .catch((errorMsg) => {
                        error(errorMsg);
                      });

                  } catch (errorMsg) {
                    error(errorMsg);
                  }
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        } else {
          PkCommons.waitUntil(() => {
            return !PkMedia._gettingArVideoProcessedTrack;
          }).then(() => {
            success();
          });
        }
      } else {
        reject("ar is not enabled");
      }
    });
  };

  static stopArVideoProcessedTrack = () => {
    if (PkMedia.isArEnabled()) {
      PkMedia.stopTrack(PkMedia._arVideoProcessedTrack);
      PkMedia._resetArVideoSegmentation();
    }
  };

  static stopArVideoInputDeviceTrack = () => {
    if (PkMedia.isArEnabled()) {
      PkMedia.stopArVideoProcessedTrack();
      PkMedia.stopTrack(PkMedia._arVideoInputDeviceTrack);
    }
  };

  static selectArVideoInputDevice = (device) => {
    if (PkMedia.isArEnabled()) {
      let deviceId = device ? device.deviceId : null;
      if (!device) {
        device = null;
      }

      let selectedDevice = PkStore.getState().selectedVideoInputDevice;
      let selectedArDevice = PkStore.getState().selectedArVideoInputDevice;
      if (
        !PkMedia.equalDevice(device, selectedArDevice) &&
        !PkMedia.equalDevice(selectedArDevice, selectedDevice)
      ) {
        PkStorage.setMediaInputVideoAR(deviceId);
        PkStore.get().dispatch(
          PkReduxActions.setSelectedArVideoInputDevice(device)
        );
        PkArVideoSegmentation.resetTraining();
        PkMedia.resetArVideoInputDeviceTrack();
      }
    }
  };

  static getSelectedArVideoInputDevice = () => {
    let videoInput = null;
    if (PkMedia.isArEnabled()) {
      videoInput = PkStore.getState().selectedArVideoInputDevice;

      //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
      if (
        !videoInput &&
        PkMedia._devices &&
        PkMedia._devices.input &&
        PkMedia._devices.input.video
      ) {
        let storageDeviceId = PkStorage.getMediaInputVideoAR();
        if (storageDeviceId) {
          videoInput = PkMedia.getDeviceFromList(
            PkMedia._devices.input.video,
            storageDeviceId
          );
          PkMedia.selectArVideoInputDevice(videoInput);
        }
      }
    }

    return videoInput;
  };

  /***************************
   * AUDIO INPUT
   **************************/
  static _gettingAudioInputDeviceTrack = false;
  static _audioInputDeviceTrack = null;
  static getAudioInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      if (!PkMedia._gettingAudioInputDeviceTrack) {
        PkMedia._gettingAudioInputDeviceTrack = true;
        PkMedia.stopTrack(PkMedia._audioInputDeviceTrack);

        let device = PkMedia.getSelectedAudioInputDevice();
        if (!device) {
          PkMedia._audioInputDeviceTrack = null;
          PkMedia._gettingAudioInputDeviceTrack = false;
          resolve(PkMedia._audioInputDeviceTrack);
        } else {
          if (
            PkMedia.isDeviceTrackEnabled(device, PkMedia._audioInputDeviceTrack)
          ) {
            PkMedia._gettingAudioInputDeviceTrack = false;
            resolve(PkMedia._audioInputDeviceTrack);
          } else {
            PkApiCallsCommunicationProvider.getTrackFromLocalAudioInputDevice(
              device,
              true
            )
              .then((track) => {
                PkMedia._audioInputDeviceTrack = track;
                PkMedia._audioInputDeviceTrack.deviceId = device.deviceId;
                resolve(PkMedia._audioInputDeviceTrack);
              })
              .catch((error) => {
                PkLog.error("getAudioInputDeviceTrack ERROR", error);
                PkMedia._audioInputDeviceTrack = null;
                reject(error);
              })
              .finally(() => {
                PkMedia._gettingAudioInputDeviceTrack = false;
              });
          }
        }
      } else {
        PkCommons.waitUntil(() => {
          return !PkMedia._gettingAudioInputDeviceTrack;
        }).then(() => {
          resolve(PkMedia._audioInputDeviceTrack);
        });
      }
    });
  };

  static stopAudioInputDeviceTrack = () => {
    if (
      PkMedia._audioInputDeviceTrack &&
      !PkMedia._audioInputDeviceTrack.isStopped
    ) {
      PkMedia._audioInputDeviceTrack.stop();
    }
  };

  static selectAudioInputDeviceId = (deviceId) => {
    let device = PkMedia.getDeviceFromList(
      PkStore.getState().audioInputDevices,
      deviceId
    );
    PkMedia.selectAudioInputDevice(device);
  };

  static selectAudioInputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;
    if (!device) {
      device = null;
    }

    let selectedDevice = PkStore.getState().selectedAudioInputDevice;
    if (!PkMedia.equalDevice(device, selectedDevice)) {
      PkStorage.setMediaInputAudio(deviceId);
      PkStore.get().dispatch(
        PkReduxActions.setSelectedAudioInputDevice(device)
      );

      if (device && PkApiCalls.getActiveCall()) {
        PkApiCalls._onSelectedAudioInputDeviceChanged(device);
      }
    }
  };

  static getSelectedAudioInputDevice = () => {
    let audioInput = PkStore.getState().selectedAudioInputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !audioInput &&
      PkMedia._devices &&
      PkMedia._devices.input &&
      PkMedia._devices.input.audio
    ) {
      let storageDeviceId = PkStorage.getMediaInputAudio();
      if (storageDeviceId) {
        audioInput = PkMedia.getDeviceFromList(
          PkMedia._devices.input.audio,
          storageDeviceId
        );
        PkMedia.selectAudioInputDevice(audioInput);
      }
    }

    return audioInput;
  };

  /***************************
   * AUDIO OUTPUT
   **************************/
  static selectAudioOutputDeviceId = (deviceId) => {
    let device = PkMedia.getDeviceFromList(
      PkStore.getState().audioOutputDevices,
      deviceId
    );
    PkMedia.selectAudioOutputDevice(device);
  };

  static selectAudioOutputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;

    if (!device) {
      device = null;
    }

    let selectedDevice = PkStore.getState().selectedAudioOutputDevice;
    if (!PkMedia.equalDevice(device, selectedDevice)) {
      PkStorage.setMediaOutputAudio(deviceId);
      PkStore.get().dispatch(
        PkReduxActions.setSelectedAudioOutputDevice(device)
      );
    }
  };

  static getSelectedAudioOutputDevice = () => {
    let audioOutput = PkStore.getState().selectedAudioOuputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !audioOutput &&
      PkMedia._devices &&
      PkMedia._devices.output &&
      PkMedia._devices.output.audio
    ) {
      let storageDeviceId = PkStorage.getMediaOutputAudio();
      if (storageDeviceId) {
        audioOutput = PkMedia.getDeviceFromList(
          PkMedia._devices.output.audio,
          storageDeviceId
        );
      }
    }

    return audioOutput;
  };

  /***************************
   * COMMONS
   **************************/

  static isDeviceTrackEnabled = (device, track) => {
    return (
      device && track && track.deviceId === device.deviceId && !track.isStopped
    );
  };

  static equalDevice = (deviceA, deviceB) => {
    return !(
      (deviceA && deviceB === null) ||
      (!deviceA && deviceB !== null) ||
      (deviceA && deviceB && deviceA.deviceId !== deviceB.deviceId)
    );
  };

  static equalInputDevices = (listA, listB) => {
    if ((listA === null && listB !== null) || (listA != null && listB === null))
      return false;

    if (listA === null) return true;

    if (listA.length !== listB.length) return false;

    for (var a = 0; a < listA.length; a++) {
      let containsDevice = false;
      for (var b = 0; b < listB.length; b++) {
        if (listA[a].deviceId === listB[b].deviceId) {
          containsDevice = true;
          break;
        }
      }
      if (!containsDevice) return false;
    }
    return true;
  };

  static getDeviceFromList = (deviceList, deviceId) => {
    if (!deviceList || !deviceId) return null;

    for (var i = 0; i < deviceList.length; i++) {
      if (deviceList[i].deviceId === deviceId) return deviceList[i];
    }
    return null;
  };

  static devicesListContains = (deviceList, device) => {
    if (!deviceList || !device) return false;

    for (var i = 0; i < deviceList.length; i++) {
      if (deviceList[i].deviceId === device.deviceId) return true;
    }
    return false;
  };

  static playAudio = (audio) => {
    if (audio) {
      PkMedia.stopAudio();
      playingAudio = audio;
      const audioPromise = audio.play();
      if (audioPromise !== undefined) {
        audioPromise
          .then((_) => { })
          .catch((err) => {
            console.info(err);
          });
      }
    }
  };

  static stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
    }
  };
}
export default PkMedia;
