import { PkStore } from "../redux/store";
import { PkReduxActions } from "../redux/actions";
import { PkLog } from "../log";

export class PkArVideoSegmentation {
  static _nbgdframes = 30; //30
  static LEARNING_FRAMES = PkArVideoSegmentation._nbgdframes;
  static _detectShadow = true;
  static _fgbgsubtractor = null;

  constructor(video, options) {
    if (!options) {
      PkLog.error("missing options parameter");
    } else if (!options.targetCanvas) {
      PkLog.error("missing options.targetCanvas parameter");
    }

    let cv = window.cv;

    this.video = video;

    this.height = this.video.height;
    this.width = this.video.width;

    this.targetCanvas = options.targetCanvas;
    this.trainingOnly = options.trainingOnly;

    this.src = new cv.Mat(this.height, this.width, cv.CV_8UC4);
    this.srcRGB = new cv.Mat(this.height, this.width, cv.CV_8UC3);
    this.dst = new cv.Mat(this.height, this.width, cv.CV_8UC4);

    this.mask_overlay = new cv.Mat(this.height, this.width, cv.CV_8UC1);
    this.mask_overlay.setTo(new cv.Scalar(0));

    this.fgd_mask = new cv.Mat(this.height, this.width, cv.CV_8UC1);

    this.overlay_red = new cv.Mat(this.height, this.width, cv.CV_8UC3);
    this.overlay_red.setTo(new cv.Scalar(255, 0, 0));

    this.FPS = 30;
    this.backgroundImage = null;

    if (!PkArVideoSegmentation._fgbgsubtractor) {
      PkArVideoSegmentation._fgbgsubtractor = new cv.BackgroundSubtractorMOG2(
        500,
        24,
        PkArVideoSegmentation._detectShadow
      );
    }

    this.cap = new cv.VideoCapture(this.video);

    PkStore.get().dispatch(
      PkReduxActions.setArVideoProcessState(false, false, 0, false)
    );
  }

  setProcessing = (processing) => {
    PkStore.get().dispatch(PkReduxActions.setArVideoProcessState(processing));
  };

  isProcessing = () => {
    return PkStore.getState().arVideoProcessing;
  };

  onProcessError = null;

  processVideo = () => {
    let cv = window.cv;
    try {
      if (!this.streaming) {
        //esci se c'è qualche problema con la camera
        // clean and stop.
        this.src.delete();
        this.dst.delete();
        return;
      }
      let begin = Date.now();
      // start processing.
      this.cap.read(this.src); //cap è la sorgente video, leggi un frame delal camera in src
      cv.cvtColor(this.src, this.srcRGB, cv.COLOR_RGBA2RGB); //converti src da RGBA a RGB in srcRGB
      if (PkArVideoSegmentation._nbgdframes > 0) {
        if (!PkArVideoSegmentation.isTrainingStarted()) {
          PkArVideoSegmentation._onTrainingStart();
        }
        let progress = parseInt(
          100 -
          (PkArVideoSegmentation._nbgdframes * 100) /
          PkArVideoSegmentation.LEARNING_FRAMES
        );
        PkArVideoSegmentation.setTrainingProgress(progress);
        //qui viene creato il modello del background per i primi 30 frame. 30 è un parametro che può essere cambiato sopra
        //Sarebbe da informare l'utente finche nbgdframes>0 di non mettere niente in vista della camera
        PkArVideoSegmentation._nbgdframes =
          PkArVideoSegmentation._nbgdframes - 1;
        //this.backgroundImage.copyTo(this.dst);
        this.dst.setTo(new cv.Scalar(0, 0, 0, 0));
        PkArVideoSegmentation._fgbgsubtractor.apply(
          this.srcRGB,
          this.fgd_mask,
          0.005
        );
        this.enhanceMog(this.srcRGB, 0.005);
        this.srcRGB.copyTo(this.dst, this.fgd_mask);
      } else {
        if (!PkArVideoSegmentation.isTrainingEnded()) {
          PkArVideoSegmentation._onTrainingEnded();
        }

        if (this.trainingOnly === true) {
          this.stopProcessing();
        }

        //qui è dove avviene la sottrazione del background dopo aver fatto i 30 frame di inizializzazione
        PkArVideoSegmentation._fgbgsubtractor.apply(
          this.srcRGB,
          this.fgd_mask,
          0.0
        ); //passo l'immagine della camera a fgbgsubtractor e ottengo una maschera (simile al canale alpha) fgd_mask
        //this.backgroundImage.copyTo(this.dst);//backgroundImage è l'immagine di sfondo da sostituire al background la copio in dst
        this.dst.setTo(new cv.Scalar(0, 0, 0, 0));
        //fgd_mask &= (fgd_mask != 127);
        if (PkArVideoSegmentation._detectShadow) {
          cv.threshold(
            this.fgd_mask,
            this.fgd_mask,
            127,
            255,
            cv.THRESH_BINARY
          );
          //                    cv.convertScaleAbs(this.fgd_mask, this.fgd_mask,2,0);
        }
        //applico dello smoothing alla maschera per migliorarla un po
        cv.medianBlur(this.fgd_mask, this.fgd_mask, 5);
        cv.GaussianBlur(this.fgd_mask, this.fgd_mask, new cv.Size(5, 5), 0.0);
        //a questo punto fgd_mask è un immagine in scala di grigio stile canale alpha
        //roba non usata perche lenta e commentata via
        //var r, c;
        //for (r = 0; r < srcRGB.rows; ++r) {
        //	for (c = 0; c < srcRGB.cols; ++c) {
        //		var valmask = fgd_mask.ucharPtr(r, c)[0] / 255.0;
        //		var norm = (srcRGB.ucharPtr(r, c)[0]+srcRGB.ucharPtr(r, c)[1]+srcRGB.ucharPtr(r, c)[2])/3.0;
        //		valmask *= Math.min(1.0, Math.max(((norm - 25.0) / 60.0), 0.0));
        //		dst.ucharPtr(r, c)[0] = srcRGB.ucharPtr(r, c)[0]*valmask + (1 - valmask)*dst.ucharPtr(r, c)[0];
        //		dst.ucharPtr(r, c)[1] = srcRGB.ucharPtr(r, c)[1]*valmask + (1 - valmask)*dst.ucharPtr(r, c)[1];
        //		dst.ucharPtr(r, c)[2] = srcRGB.ucharPtr(r, c)[2]*valmask + (1 - valmask)*dst.ucharPtr(r, c)[2];
        //	}
        //}
        //qui copio lo stream della camera su dst usando il canale alpha per fare blending
        this.srcRGB.copyTo(this.dst, this.fgd_mask);
        //qui copio sopra tutto overlay_red che dovrebbe contenere i disegni fatti sopra lo stream
        // this.overlay_red.copyTo(this.dst, this.mask_overlay);
      }
      cv.imshow(this.targetCanvas, this.dst);
      // schedule the next one.
      let delay = 1000 / this.FPS - (Date.now() - begin);
      if (PkArVideoSegmentation._nbgdframes > 0) {
        delay = 100;
      }

      const self = this;
      setTimeout(() => {
        self.processVideo();
      }, delay);
    } catch (err) {
      PkLog.error(err);
      if (this.onProcessError) {
        this.onProcessError(err);
      }
    }
  };

  _setupBackgroundImage = () => {
    let cv = window.cv;
    if (this.backgroundImage) {
      cv.resize(
        this.backgroundImage,
        this.backgroundImage,
        new cv.Size(this.src.cols, this.src.rows)
      );
    } else {
      this.backgroundImage = new cv.Mat(this.height, this.width, cv.CV_8UC3);
      this.backgroundImage.setTo(new cv.Scalar(0, 0, 0));
    }
  };

  startProcessing = () => {
    this.streaming = true;

    this.setProcessing(true);

    const self = this;
    setTimeout(() => {
      self.processVideo();
    }, 0);

    this._setupBackgroundImage();
  };

  stopProcessing = () => {
    this.streaming = false;
    this.setProcessing(false);
  };

  static initVideo = (video, options) => {
    return new PkArVideoSegmentation(video, options);
  };

  // This functions modifies each frame slightly to make the background subtraction more aggressive
  enhanceMog = (frame, rate) => {
    let cv = window.cv;
    var mask, i;
    mask = new cv.Mat(frame.rows, frame.cols, cv.CV_8UC1);
    for (i = 0; i < 10; ++i) {
      var temp = frame.clone();
      //var vec = new cv.Vec3b(2 * i, 2 * i, 2 * i)
      cv.convertScaleAbs(frame, temp, 1, 2 * i);
      PkArVideoSegmentation._fgbgsubtractor.apply(temp, mask, rate);
      cv.convertScaleAbs(frame, temp, 1, -2 * i);
      PkArVideoSegmentation._fgbgsubtractor.apply(temp, mask, rate);
    }
    mask.delete();
  };

  static resetTraining = () => {
    let cv = window.cv;
    PkArVideoSegmentation._nbgdframes = 30; //30
    PkArVideoSegmentation.LEARNING_FRAMES = PkArVideoSegmentation._nbgdframes;
    PkArVideoSegmentation._fgbgsubtractor = new cv.BackgroundSubtractorMOG2(
      500,
      24,
      PkArVideoSegmentation._detectShadow
    );
    PkStore.get().dispatch(
      PkReduxActions.setArVideoTrainingState(false, 0, false)
    );
  };

  static _onTrainingStart = () => {
    PkArVideoSegmentation.setTrainingStarted(true);
    if (PkArVideoSegmentation.onTrainingStart) {
      PkArVideoSegmentation.onTrainingStart();
    }
  };
  static _onTrainingEnded = () => {
    PkArVideoSegmentation.setTrainingEnded(true);
    if (PkArVideoSegmentation.onTrainingEnded) {
      PkArVideoSegmentation.onTrainingEnded();
    }
  };

  static setTrainingStarted = () => {
    PkStore.get().dispatch(
      PkReduxActions.setArVideoTrainingState(true, 0, false)
    );
  };

  static setTrainingEnded = () => {
    PkStore.get().dispatch(
      PkReduxActions.setArVideoTrainingState(true, 100, true)
    );
  };

  static isTrainingStarted = () => {
    return PkStore.getState().arVideoTrainingStarted;
  };

  static isTrainingEnded = () => {
    return PkStore.getState().arVideoTrainingEnded;
  };

  static setTrainingProgress = (progress) => {
    PkStore.get().dispatch(
      PkReduxActions.setArVideoTrainingState(
        PkArVideoSegmentation.isTrainingStarted(),
        progress,
        progress === 100
      )
    );
  };
}
export default PkArVideoSegmentation;
