import React from 'react';
import { Page, PageContent, Navbar } from 'framework7-react';

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';


class PhotoGallery extends React.Component {

    constructor() {
        super();
        this.state = {
            taken_picture: null,
        };
    }

    render() {

        const settedPlate = (
            this.props.userFeedbackText ?
                <div><img style={{ width: '100vw' }} src={process.env.PUBLIC_URL + '/img/data_set/' + this.props.userFeedbackText + '.png'} alt={'Plate searched: ' + this.props.userFeedbackText} /><p>&nbsp;&nbsp;{'Plate searched: ' + this.props.userFeedbackText}</p></div>
                : ""
        )
        let tagImages = "";
        if (this.props.result) {
            const images = this.props.result.map((item, index) => {

                var imageAlt = item.content.tag + ' - ' + item.content.title;

                return (
                    {
                        imageTag: <div><img style={{ width: '100vw' }} src={process.env.PUBLIC_URL + '/img/data_set/' + item.content.tag + '.png'} alt={imageAlt} /><p>&nbsp;&nbsp;{imageAlt}</p></div>
                    }
                );
            });

            tagImages = images.map((item, index) => {
                return (
                    <div>
                        {item.imageTag}
                    </div>
                )
            });
        }

        const takenPictureName = "Taken Picture";

        return (
            <Page themeDark={true} >
                <Navbar>
                    <PikkartNavbar
                        title='Results'
                        logo='./img/logo/logo.png'
                        showNavRight={true}
                        showArrowAsNavRight={true} />
                </Navbar>
                <PageContent>
                    <SimpleReactLightbox>
                        <SRLWrapper>
                            <div id="content-page-one" className="container content">
                                <div className="row">
                                    <div>
                                        <div>
                                            <img src={this.state.taken_picture} alt="Taken shot" srl_elementid="2" style={{ width: '100vw' }} />
                                            <p>&nbsp;&nbsp;{takenPictureName}</p>
                                        </div>
                                    </div>
                                    <div>
                                        {settedPlate}
                                    </div>
                                    {tagImages}

                                    {/*<div className="col-md-6 col-12 col-image-half">
                                        <img src={process.env.PUBLIC_URL + '/img/GFAA5A1U010A2.png'} alt="New York City - Architecture" />
                                    </div>
                                    <div className="col-md-6 col-12 col-image-half">
                                        <img src={process.env.PUBLIC_URL + '/img/MSL7961530.png'} alt="Between two mountains" />
                                    </div>
                                    <div className="col-md-4 col-12 col-image-small">
                                        <img src={process.env.PUBLIC_URL + '/img/MML5661530.png'} alt="Parallels building" />
                                    </div>
                                    <div className="col-md-4 col-12 col-image-small">
                                        <img src={process.env.PUBLIC_URL + '/img/MML5261025.png'} alt="The mist in the forest" />
                                    </div>
                                    <div className="col-md-4 col-12 col-image-small">
                                        <img src={process.env.PUBLIC_URL + '/img/MML3861530.png'} alt="A beautiful landscape" />
                                    </div>
                                    <div className="col-12 col-md-6 col-image-half">
                                        <img src={process.env.PUBLIC_URL + '/img/MML2361530.png'} alt="Night in new york" />
                                    </div>
                                    <div className="col-12 col-md-6 col-image-half">
                                        <img src={process.env.PUBLIC_URL + '/img/MML1761530.png'} alt="Be proud!!!" />
                                    </div>
                                    <div className="col-6 col-image-large-half">
                                        <img src={process.env.PUBLIC_URL + '/img/GFAA5B1U010A2.png'} alt="Sunset road..." />
                                    </div>
                                    <div className="col-6 col-image-large-half">
                                        <img src={process.env.PUBLIC_URL + '/img/GFAA800N020A2.png'} alt="A stunning lake" />
                </div>*/}
                                </div>
                            </div>
                        </SRLWrapper>
                    </SimpleReactLightbox>
                </PageContent>
            </Page >
        );
    }

    componentDidMount = () => {
        const imageUrl = this.convertBase64ToBlob('data:image/jpeg;base64,' + this.props.takenPicture);

        this.setState({ taken_picture: imageUrl });
    }
    convertBase64ToBlob = (base64Image) => {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        const blob = new Blob([uInt8Array], { type: imageType });

        const url = window.URL.createObjectURL(blob, { type: 'image/jpeg' });
        return url;
    }

    onBackClicked = () => {
        this.props.f7router.back({
            url: '/',
            force: true,
        });
    }
}

// #region Redux
/*const mapStateToProps = state => {
    return {
 
    };
};
 
const mapDispatchToProps = dispatch => {
    return {
 
    }
};*/

// #endregion

export default PhotoGallery;