import React from 'react';
import { connect } from 'react-redux';
import { f7, Page, Navbar, Toolbar, Tabs, Tab, Link, Block } from 'framework7-react';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Commons from '../../commons'
import AnalyticsRegister, { ANALYTICS_SCREEN } from '../../analytics-register';
import appLocaleStrings from '../../app-local';
import localeStrings from './members-area-local';
import PkApiAuth from '../../pikkart-cms/api/auth';
import PkStorage from '../../pikkart-cms/storage';
import LogOutPopup from '../../components/logout/logout-popup';
import { PkApiContacts, CONTACT_ROLE } from '../../pikkart-cms/api/contacts';
import ContentListItem from '../../contents/content-list-item';
import PkContents from '../../pikkart-cms/contents';
import PkArDiscover from '../../pikkart-cms/ar/discover';
import PkLog from '../../pikkart-cms/log';


class MembersArea extends React.Component {
    constructor(props) {
        super(props);
        this.arScenesPage = 0;
        this.arScenesPageSize = 10;
        this.arViewPage = 0;
        this.arViewPageSize = 10;

        this.state = {
            allowInfinite: true,
            company: null,
            selectedCompany: null,
            companyCode: null,
            arScenesErrorMessage: null,
            arViewErrorMessage: null,
            showPreloader: true,
            customers: [],
            logoutPopupOpened: false,
            arScenesContentsData: [],
            filteredArScenesContentsData: [],
            arViewContentsData: [],
            filteredArViewContentsData: [],
        };
    }

    render() {

        const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        //TODO: cambiare con members-area
        const showLogoutButton = (menuItem && menuItem.code === '/pre-tutorial') && PkApiAuth.isAuthenticated();

        return (
            <Page
                pageContent={false}
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={() => {
                    this.fetchArScenesContents(this.state.companyCode, false);
                    this.fetchArViewContents(this.state.companyCode, false);
                }}
                hideToolbarOnScroll
                hideBarsOnScroll
                hideNavbarOnScroll
            >
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        defaultCustomer={this.state.company}
                        userCustomers={this.state.customers}
                        allowPrivateContents={process.env.REACT_APP_ALLOW_PRIVATE_CONTENTS_FILTER}
                        showChangeCustomerButton={this.state.customers.length > 1}

                        applyFiltersButtonClicked={this.onApplyFiltersButtonClicked}

                        // changeCustomerButtonClicked={this.navBarChangeCustomerClickHandler}
                        showLogoutButton={showLogoutButton}
                        logoutButtonClicked={this.navBarLogoutClickHandler}
                        //searchBarEnabled={JSON.parse(process.env.REACT_APP_ENABLE_SEARCH_BAR)}
                        //searchBarId={'searchbar-' + menuItem.id}
                        onSearch={this.onSearchBarSearchHandler}
                    />
                </Navbar>
                <Toolbar tabbar position={'top'} >
                    <Link tabLink="#tab-ar-scenes" tabLinkActive>{localeStrings.ar_involve_title}</Link>
                    <Link tabLink="#tab-ar-views">{localeStrings.ar_planar_title}</Link>
                </Toolbar>

                {/* <PageContent style={{ padding: '0px' }}> */}
                <Tabs animated>
                    <Tab id="tab-ar-scenes" className="page-content" tabActive>
                        <div>
                            {this.getArScenesContentsData()}
                        </div>
                    </Tab>
                    <Tab id="tab-ar-views" className="page-content">
                        <div>
                            {this.getArViewContentsData()}
                        </div>
                    </Tab>
                </Tabs>

                <LogOutPopup
                    closeIconClick={this.closeIconClickHandler}
                    logoutDialogConfirmed={this.logoutDialogConfirmClickHandler}
                    context={this}
                    navBarTitle={localeStrings.logout_screen_title}
                    opened={this.state.logoutPopupOpened}
                    showNavRight={false}
                    logo='./img/logo/logo.png'
                />
                {/* </PageContent> */}
            </Page>)
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.MEMBERS_AREA);
        if (navigator.onLine) {
            let company = null;
            let companyCode = null;
            const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
            if (menuItem && menuItem.code === '/pre-tutorial') {//TODO: cambiare con members-area
                Commons.checkAuthentication(this, this.props.f7router, menuItem.code);
                if (!PkApiAuth.isAuthenticated()) {
                    //return;
                }

                company = PkStorage.getLoginCompany();
                if (company) companyCode = company.Code;

                if (this.props.currentFilteredCustomer) {
                    this.setState({ company: company, selectedCompany: company, companyCode: this.props.currentFilteredCustomer }, () => {
                        this.fetchCompanyList();
                        this.fetchArScenesContents(this.props.currentFilteredCustomer, false);
                        this.fetchArViewContents(this.props.currentFilteredCustomer, false);
                    })
                }
                else if (company) {
                    this.setState({ company: company, selectedCompany: company, companyCode: companyCode }, () => {
                        this.fetchCompanyList();
                        this.fetchArScenesContents(companyCode, false);
                        this.fetchArViewContents(companyCode, false);
                    })
                }
            }
        }
        else {
            this.setState({ arScenesErrorMessage: appLocaleStrings.no_contents, arViewErrorMessage: appLocaleStrings.no_contents, showPreloader: false }, () => {
                this.showToastBottom(appLocaleStrings.no_connection_message);
            })
        }
    }

    showToastBottom = (text) => {
        this.toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 2000,
        });
        this.toastBottom.open();
    };

    /* #region Companies */
    fetchCompanyList = () => {
        this.startGetCompanies();
    }

    startGetCompanies = () => {
        const self = this;
        this.getCompanies().then(function (customers) {
            if (customers.length >= 1) {
                self.setState({ customers: customers })
            }
            else {
                self.setState({ customers: [] })
            }
        })
            .catch(function (error) {
                if (error.errorCode === 401) {
                    //invalid token
                    const locRouter = self.props.f7router;
                    const menuItem = Commons.getMenuItemFromRoute(self.props.f7route, self.props.menu);
                    //rimando al login
                    setTimeout(function () {
                        localStorage.setItem('menu', JSON.stringify(self.props.menu));
                        localStorage.setItem('skip_ar_logo_tutorial', self.props.skippedArLogoTutorial);
                        localStorage.setItem('skip_ar_scene_tutorial', self.props.skippedArSceneTutorial);

                        if (locRouter.app) {
                            locRouter.app.views.main.router.navigate('/login/',
                                {
                                    reloadCurrent: true,
                                    ignoreCache: true,
                                    props: {
                                        redirectPage: menuItem.code
                                    }
                                });
                        }
                    }, 500);
                }
                this.setState({ arScenesErrorMessage: error, arViewErrorMessage: error, showPreloader: false });
            });
    }

    getCompanies = () => {
        return new Promise((resolve, reject) => {
            const errorFn = (error) => {
                reject(error);
            }

            const successFn = (customers) => {
                resolve(customers);
            }

            PkApiContacts.getCompanies()
                .then(function (response) {

                    if (response.result.success === true) {
                        // handle success
                        successFn(response.data);

                    } else {
                        // handle error
                        errorFn({ errorCode: response.result.code, errorMsg: response.result.message });
                    }
                })
                .catch(function (errorMessage) {
                    //handle error
                    errorFn({ errorMsg: errorMessage });
                });

        });
    };
    /* #endregion Companies */

    /* #region ArScenes */
    fetchArScenesContents = (owCode, resetPages) => {
        //se devo resettare non devo controllare che il caricamento infinito sia consentito
        if (!resetPages) {
            if (!this.state.allowInfinite) return;
        }
        this.setState({ allowInfinite: false })
        if (resetPages) {
            this.arScenesPage = 0;
        }
        this.arScenesPage += 1;

        let params = {
            c1: this.props.c1,
            c2: this.props.c2,
            c3: this.props.c3,
            page: this.arScenesPage,
            pageSize: this.arScenesPageSize,
            ac: this.props.onlyPrivateContentsVisible ? 2 : 1
        }

        if (owCode) {
            params.owCode = owCode;
        }


        PkContents.getArScenesContentsDataPage(params)
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.arScenesContentsData.length > 0 && !resetPages) {
                            this.setState({ showPreloader: false });
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else {
                        successFn(response.data.items);
                    }
                }
                else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });

        const errorFn = (errorMsg) => {
            if (this.arScenesPage !== 1) {
                this.setState({ showPreloader: false });
                return;
            }
            this.setState({ arScenesErrorMessage: errorMsg, showPreloader: false, allowInfinite: true });
            if (resetPages) {
                this.setState({ arScenesContentsData: [], filteredArScenesContentsData: [] });
            }
        }

        const successFn = (responseArScenesContentsData) => {
            const updatedContentsData = this.state.arScenesContentsData.concat(responseArScenesContentsData);
            this.setState({
                arScenesContentsData: resetPages ? responseArScenesContentsData : updatedContentsData,
                filteredArScenesContentsData: resetPages ? responseArScenesContentsData : updatedContentsData,
                arScenesErrorMessage: null,
                showPreloader: false
            })

            if (responseArScenesContentsData.length === this.arScenesPageSize) {
                this.setState({ allowInfinite: true });
            }
        }
    }

    getArScenesContentsData = () => {
        if (this.state.arScenesContentsData === null ||
            this.state.arScenesContentsData.length === 0) {
            if (this.state.arScenesErrorMessage) {
                return (
                    <div id="block-ar-scenes-result">
                        <Block style={{ textAlign: 'center', color: '#666' }}>
                            {String(this.state.arScenesErrorMessage)}
                        </Block>
                    </div>
                );
            }
            else
                return null;
        }

        const ret = this.state.filteredArScenesContentsData.map((item, index) => {
            return (<ContentListItem
                currentCompany={this.state.selectedCompany}
                contentData={item}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onArScenesCardClick(item);
                }}></ContentListItem>);
        });


        return (
            <div id="block-ar-scenes-result">
                <Block style={{ padding: '0' }}>
                    <div className="demo-expandable-cards">{ret}</div>
                </Block>
            </div>)
    }
    /* #endregion */

    /* #region ArView */
    fetchArViewContents = (owCode, resetPages) => {
        //se devo resettare non devo controllare che il caricamento infinito sia consentito
        if (!resetPages) {
            if (!this.state.allowInfinite) return;
        }
        this.setState({ allowInfinite: false })
        if (resetPages) {
            this.arViewPage = 0;
        }
        this.arViewPage += 1;

        let params =
        {
            c1: this.props.arview_c1,
            c2: this.props.arview_c2,
            c3: this.props.arview_c3,
            page: this.arViewPage,
            pageSize: this.arViewPageSize,
            ac: this.props.onlyPrivateContentsVisible ? 2 : 1,
        };
        if (owCode) {
            params.owCode = owCode;
        }

        PkContents.getInfoContentsDataPage(params)
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.arViewContentsData.length > 0 && !resetPages) {
                            this.setState({ showPreloader: false })
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else
                        successFn(response.data.items);
                } else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });


        const errorFn = (errorMsg) => {
            if (this.arViewPage !== 1) {
                this.setState({ showPreloader: false })
                return;
            }
            this.setState({ arViewErrorMessage: errorMsg, showPreloader: false, allowInfinite: true });
            if (resetPages) {
                this.setState({ arViewContentsData: [], filteredArViewContentsData: [] })
            }
        }

        const successFn = (responseArViewContentsData) => {
            const updatedContentsData = this.state.arViewContentsData.concat(responseArViewContentsData);
            this.setState({
                arViewContentsData: resetPages ? responseArViewContentsData : updatedContentsData,
                filteredArViewContentsData: resetPages ? responseArViewContentsData : updatedContentsData,
                arViewErrorMessage: null,
                showPreloader: false
            })
            if (responseArViewContentsData.length === this.arViewPageSize) {
                this.setState({ allowInfinite: true });
            }
        }
    }

    getArViewContentsData = () => {
        if (this.state.arViewContentsData === null ||
            this.state.arViewContentsData.length === 0) {
            if (this.state.arViewErrorMessage) {
                return (
                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.arViewErrorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const profile = PkStorage.getProfile();
        const isContentEditable = profile && profile.Role && (profile.Role.Code === CONTACT_ROLE.OPERATOR || profile.Role.Code === CONTACT_ROLE.ADMIN);

        const ret = this.state.filteredArViewContentsData.map((item, index) => {
            return (<ContentListItem
                isContentEditable={isContentEditable}
                currentCompany={this.state.selectedCompany}
                contentData={item}
                key={'info-content-' + index}
                onCardClickHandler={(item) => {
                    this.onArViewCardClick(item);
                }}
                onEditContentButtonClick={(content) => {
                    f7.preloader.show();
                    const self = this;
                    this.getModel(content.extlId).then((modelData) => {
                        self.props.f7router.navigate('/discover-views', {
                            reloadAll: true,
                            props: {
                                modelData: modelData
                            }
                        });
                    })
                        .catch((error) => {
                            PkLog.error('errorCode: ' + error.errorCode, error.errorMsg);
                            this.showToastBottom(error.errorMsg);
                        })
                        .finally(() => {
                            f7.preloader.hide();
                        });
                }}></ContentListItem>);
        });


        return (
            <Block style={{ padding: '0' }}>
                <div className="demo-expandable-cards">{ret}</div>
            </Block>)
    }

    getModel = (modelId) => {
        return new Promise((resolve, reject) => {
            const successFn = (modelData) => {
                resolve(modelData);
            }
            const errorFn = (error) => {
                reject(error);
            }

            PkArDiscover.getModel(modelId)
                .then((response) => {
                    if (response.result.success === true) {
                        successFn(response.data);
                    } else {
                        errorFn({ errorCode: response.result.code, errorMsg: response.result.message });
                    }
                }).catch((errorMessage) => {
                    errorFn({ errorMsg: errorMessage });
                });
        })
    }
    /* #endregion */

    onApplyFiltersButtonClicked = (currentFilteredCustomer, onlyPrivateContentsVisible) => {
        // console.log('currentFilteredCustomer', currentFilteredCustomer);
        // console.log('onlyPrivateContentsVisible', onlyPrivateContentsVisible);
        //f7.smartSelect.get('.smart-select').$selectEl.val();
        console.log('onlyPrivateContentsVisible', onlyPrivateContentsVisible);
        let selectedCustomer;
        this.state.customers.forEach(customer => {
            if (customer.Code === currentFilteredCustomer)
                selectedCustomer = customer
        });
        this.setState({ allowInfinite: true, showPreloader: true, selectedCompany: selectedCustomer }, () => {
            this.fetchArScenesContents(currentFilteredCustomer, true);
            this.fetchArViewContents(currentFilteredCustomer, true);
        });
    }

    navBarLogoutClickHandler = () => {
        setTimeout(() => {
            this.setState({ logoutPopupOpened: true })
        }, 100);
    }

    onSearchBarSearchHandler = (searchbar, query, previousQuery) => {
        console.log('to implement onSearchBarSearchHandler')
        // if (query) {

        //     let updatedContentsData = [];

        //     let arScenesContentsData = this.state.arScenesContentsData;
        //     arScenesContentsData.forEach(item => {
        //         if (item.content.title.toLowerCase().includes(query.toLowerCase())) {
        //             updatedContentsData.push(item);
        //         }
        //     });

        //     this.setState({ filteredArScenesContentsData: updatedContentsData })
        // }
        // else {
        //     this.setState({ filteredArScenesContentsData: this.state.arScenesContentsData })
        // }
    }

    closeIconClickHandler = () => {
        this.setState({ logoutPopupOpened: false })
    }

    logoutDialogConfirmClickHandler = () => {
        localStorage.setItem('menu', JSON.stringify(this.props.menu));

        const self = this;
        this.setState({ logoutPopupOpened: false })
        //rimando al login
        setTimeout(function () {
            const menuItem = Commons.getMenuItemFromRoute(self.props.f7route, self.props.menu);

            //TODO: cambiare con members-area
            if (menuItem && menuItem.code === '/pre-tutorial') {
                Commons.checkAuthentication(self, self.props.f7router, menuItem.code);
            }
        }, 200);
    }

    onArScenesCardClick = (item) => {
        console.log('ar scene card clicked')
        this.onCardClick(item, CONTENT_TYPE.AR_SCENE);
    }

    onArViewCardClick = (element, item) => {
        console.log('ar view card clicked')
        this.onCardClick(item, CONTENT_TYPE.AR_VIEW);
    }

    onCardClick = (item, contentType) => {
        switch (contentType) {
            case CONTENT_TYPE.AR_SCENE:
                console.log('ar scene');
                break;
            case CONTENT_TYPE.AR_VIEW:
                console.log('ar view');
                break;
            default:
                break;
        }
    }
}
// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        currentFilteredCustomer: state.app.currentFilteredCustomer,
        onlyPrivateContentsVisible: state.app.onlyPrivateContentsVisible,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MembersArea);


export const CONTENT_TYPE = {
    AR_SCENE: 1,
    AR_VIEW: 2
};