import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading: "Loading...",
    close: "Close",
    noDataTitle: "",
    noDataText: "No data available",
    urlLabel: "Web page",
    stalkingDogsPopupTitle: "Stalking dogs area",
    parkFeaturesLabel: "Features",
    ILLUMINAZIONE: "Street lamps",
    GIOCO_BIMBI: "Children's playground",
    SPAZI_SPORT: "Sports spaces",
    RISTORO: "Bar",
    ARREDI: "Benches",
    SERVIZI_IGIENICI: "WC",
    ALBERI_PREGIO: "Prestigious trees",
    ALBERI_TUTELATI: "Protected trees",
  },
  it: {
    loading: "Attendere",
    close: "Chiudi",
    noDataTitle: "",
    noDataText: "Non ci sono dati disponibili",
    urlLabel: "Pagina web",
    stalkingDogsPopupTitle: "Area sgambamento cani",
    parkFeaturesLabel: "Caratteristiche",
    ILLUMINAZIONE: "Illuminazione artificiale",
    GIOCO_BIMBI: "Giochi per bambini",
    SPAZI_SPORT: "Spazi sportivi",
    RISTORO: "Bar",
    ARREDI: "Panchine",
    SERVIZI_IGIENICI: "Bagni",
    ALBERI_PREGIO: "Alberi di pregio",
    ALBERI_TUTELATI: "Alberi tutelati",
  }
});
  
  export default localeStrings;