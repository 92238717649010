import { Pk } from "../index";
import { PkApiNet } from "../api/net";

export class PkApiProducts {
  static getProducts = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/products",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default PkApiProducts;
