
import Pk from '../index';
import PkApiNet from '../api/net';

export class PkArDeep {

    static processDeepModel = (modelName, customerCode, imageBase64, maxResults, customParams) => {
        return new Promise((resolve, reject) => {


            const bodyFormData = new FormData();
            bodyFormData.set('customerCode', customerCode);
            bodyFormData.set('base64-image', imageBase64);
            bodyFormData.set('max-results', maxResults);
            bodyFormData.set('custom-params', customParams);

            //const bodyString = "customerCode=" + customerCode + "&base64-image=" + imageBase64 + "&max-results=" + maxResults + "&custom-params=" + JSON.stringify(customParams) + "";

            PkApiNet.callApi({
                method: 'post',
                data: bodyFormData,
                url: Pk.getConfig().apiUrl + '/api/deep/predict/' + modelName,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }
}

export default PkArDeep;