import React from 'react';
import { connect } from 'react-redux';
import { f7, Card, CardContent } from 'framework7-react';
import DetailView from './detail-view';
import { PkLog } from '../pikkart-cms/log';

class ContentListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            effect: 'blink',
            isDetailVisible: false,
            contentWidth: 'auto',
            isImageVisible: true,
        };
    }

    render() {
        let item = this.props.contentData;
        const cardWidth = this.props.cardWidth ? this.props.cardWidth : ""
        return (
            <Card
                onCardOpen={() => this.onCardOpen(item)}
                onCardClose={() => this.onCardClose(item)}
                expandable
                style={{ width: cardWidth }}
                key={"contentid-" + item.content.id}>

                <CardContent padding={false} style={{ position: 'inherit', width: this.state.contentWidth }}>
                    <DetailView
                        isImageVisible={this.state.isImageVisible}
                        isDetailVisible={this.state.isDetailVisible}
                        item={item}
                        showCloseButton={true}
                        currentCompany={this.props.currentCompany}
                        onInfoClickHandler={this.props.onInfoClickHandler}
                        onEditContentButtonClick={this.props.onEditContentButtonClick}
                        isContentEditable={this.props.isContentEditable} />
                </CardContent>
            </Card>
        )
    }

    onCardOpen = (item) => {
        if (this.props.onCardClickHandler) {
            this.props.onCardClickHandler(item);
        }

        const imageDiv = f7.$('#card-image-' + item.content.id)
        this.switchClasses(imageDiv, "opened-card-width", "closed-card-width");

        this.setState({ isDetailVisible: true, contentWidth: '100vw' })
    }

    onCardClose = (item) => {
        const imageDiv = f7.$('#card-image-' + item.content.id)
        this.switchClasses(imageDiv, "closed-card-width", "opened-card-width");

        this.setState({ isDetailVisible: false, isImageVisible: false }, () => {
            setTimeout(() => {
                this.setState({ contentWidth: 'initial', isImageVisible: true })
            }, 600)
        })
    }

    switchClasses = (el, classToAdd, classToRemove) => {
        if (el) {
            if (el.hasClass(classToRemove)) {
                el.removeClass(classToRemove)
            }

            if (!el.hasClass(classToAdd)) {
                el.addClass(classToAdd)
            }
        }
    }

    componentDidMount = () => {

    }

    imageLoaded = () => {
        PkLog.log('image loaded');
        this.setState({ loading: false });
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentListItem);