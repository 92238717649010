import React from 'react';
import { connect } from 'react-redux';
import { Page } from 'framework7-react';
import { PkCordovaARSdk, PkCordova } from 'pikkart-cordova';
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../analytics-register'
import DetailView from './detail-view';
import Commons from '../commons';

class RequestedContentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Page>
                <DetailView
                    item={this.props.contentItem}
                    isDetailVisible={true}
                    showCloseButton={true}
                    closeButtonFn={this.closeModalViewClick} />
            </Page>)
    }


    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CONTENT_DETAIL);
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, this.props.contentItem)
    }

    closeModalViewClick = () => {
        if (PkCordova.isCordova())
            PkCordovaARSdk.closeModalView();
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RequestedContentDetail);