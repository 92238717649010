import { PkStore } from "../redux/store";
import { PkReduxActions } from "../redux/actions";

export class PkUITracksNodesManager {
  constructor() {
    this.participants = [];
  }

  static getInstance = () => {
    let instance = PkStore.getState().callTracksNodes;
    if (!instance) {
      instance = new PkUITracksNodesManager();
    }

    return instance;
  };

  static createParticipantTrackNode = (
    participantId,
    isAssistedParticipant
  ) => {
    let node =
      PkUITracksNodesManager.getEmptyParticipantTrackNode(participantId);
    PkUITracksNodesManager.setParticipantTracks(
      participantId,
      node,
      isAssistedParticipant
    );
  };

  static getEmptyParticipantTrackNode = (participantId) => {
    let nodeId = PkUITracksNodesManager.getTrackNodeId(participantId);
    let node = document.getElementsByClassName(nodeId);
    if (node.length === 0) {
      node = document.createElement("div");
      node.className = nodeId;
      node.className += " participant-track";
    } else {
      node = node[0];
    }

    return node;
  };

  static setLocalParticipantTracks = (trackNode, isAssistedParticipant) => {
    PkUITracksNodesManager.setParticipantTracks(
      PkUITracksNodesManager.getLocalParticipantNodeId(),
      trackNode,
      isAssistedParticipant
    );
  };

  static getTrackNodeId = (participantId) => {
    return "participant-" + participantId;
  };

  static setParticipantTracks = (
    participantId,
    trackNode,
    isAssistedParticipant
  ) => {
    let self = PkUITracksNodesManager.getInstance();

    let nodeIndex = self.getParticipantIndex(participantId);
    if (nodeIndex) {
      self.participants.splice(nodeIndex, 1);
    }
    trackNode.id = PkUITracksNodesManager.getTrackNodeId(participantId);
    self.participants.push({
      id: trackNode.id,
      node: trackNode.outerHTML,
      isAssistedParticipant: isAssistedParticipant,
    });

    PkStore.get().dispatch(PkReduxActions.setCallTracksNodes(self));
  };

  static removeParticipantTracks = (participantId) => {
    let self = PkUITracksNodesManager.getInstance();

    let nodeIndex = self.getParticipantIndex(participantId);
    if (nodeIndex) {
      self.participants.splice(nodeIndex, 1);
      PkStore.get().dispatch(PkReduxActions.setCallTracksNodes(self));
    }

    return nodeIndex ? true : false;
  };

  static removeAllParticipantsTracks = () => {
    let self = PkUITracksNodesManager.getInstance();
    self.participants = [];
    PkStore.get().dispatch(PkReduxActions.setCallTracksNodes(self));
  };

  getParticipantIndex = (participantId) => {
    for (var i = 0; i < this.participants.length; i++) {
      if (
        this.participants[i].id ===
        PkUITracksNodesManager.getTrackNodeId(participantId)
      )
        return i;
    }
    return null;
  };
}

export default PkUITracksNodesManager;
