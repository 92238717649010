import { PkLog } from "../log";
import { PkStorage } from "../storage";
import { PkCryptoRSA } from "../crypto/rsa";
import { PkCryptoAES } from "../crypto/aes";
import { Pk } from "../index";

export class PkCrypto {
  static CRYPTOSCHEMA_NONE = 0;
  static CRYPTOSCHEMA_JSON_V1 = 1;

  static encrypt = (data, contacts) => {
    if (Pk.getConfig().cryptoSchema === PkCrypto.CRYPTOSCHEMA_NONE) {
      return data;
    }

    if (contacts && contacts.length > 0) {
      if (Pk.getConfig().cryptoSchema === PkCrypto.CRYPTOSCHEMA_JSON_V1) {
        let passphrase = PkCrypto.createKey();
        let recipientKeys = contacts.map((contact) => {
          return {
            CriptedKey: PkCryptoRSA.encryptRSA(contact.PublicKey, passphrase),
            RecipientId: contact.UniqueId,
          };
        });

        let cryptedData = {
          Message: PkCryptoAES.encryptAES(data, passphrase),
          RecipientKeys: recipientKeys,
        };
        //      PkLog.log('cryptedData', cryptedData);
        return JSON.stringify(cryptedData);
      }
    } else {
      PkLog.error("contacts param is null or empty");
    }
  };

  static decrypt = (jsonMessage, cryptoSchema) => {
    if (cryptoSchema === PkCrypto.CRYPTOSCHEMA_NONE) {
      return jsonMessage;
    }

    let privateKey = PkStorage.getPrivateKey();
    if (privateKey) {
      let uniqueId = PkStorage.getProfile().UniqueId;

      if (cryptoSchema === PkCrypto.CRYPTOSCHEMA_JSON_V1) {
        let i;
        let message;
        let keyFound = false;
        if (jsonMessage && jsonMessage.RecipientKeys) {
          for (i = 0; i < jsonMessage.RecipientKeys.length; i++) {
            if (jsonMessage.RecipientKeys[i].RecipientId === uniqueId) {
              keyFound = true;

              try {
                let passphrase = PkCryptoRSA.decryptRSA(
                  privateKey,
                  jsonMessage.RecipientKeys[i].CriptedKey
                );
                //PkLog.log(i + ' Passphrase AES: ' + passphrase);

                message = PkCryptoAES.decryptAES(
                  jsonMessage.Message,
                  passphrase
                );
                /*  let _deCriptedMessageBase64 = PkCryptoAES.decryptAES(jsonMessage.Message, passphrase);
                                message = atob(_deCriptedMessageBase64);    */
              } catch (error) {
                PkLog.error("impossibile leggere il messaggio", error);
              }
            }
          }
        }
        if (!keyFound) {
          PkLog.error(
            "impossibile leggere il messaggio perché non destinato all'utente"
          );
        }

        return message;
      }
    } else {
      return null;
    }
  };

  static createKey = () => {
    let key = "";
    let hex = "0123456789abcdef";

    for (let i = 0; i < 64; i++) {
      key += hex.charAt(Math.floor(Math.random() * 16));
    }
    return key;
  };
}
export default PkCrypto;
